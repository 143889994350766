import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Loading } from 'components/index'
import { coursesStore, userStore } from 'Stores/index'
import PreviewSection from './components/PreviewSection/PrewiewSection'
import QuestionSection from './components/QuestionSection/QuestionSection'
import CoursesSection from './components/CoursesSection/CoursesSection'

const Home = observer(() => {
  useEffect(() => {
    coursesStore.getCourses()
    document.title = 'Главная'
    userStore.setFooter(false)
  }, [])

  return (
    <>
      {userStore.loading ? <Loading /> : (
        <>
          <PreviewSection/>
          <CoursesSection/>
          <QuestionSection/>
        </>
      )}
    </>
  )
})

export default Home
