export const WEBSOCKET_MSG = {
  NOTIFICATION: 'notification',
  TOP_USERS: 'top_users',
  ANOTHER_PROFILE: 'another_profile',
  CHAT: 'chat',
  USER_CHATS: 'user_chats',
  MESSAGE_CHAT: 'message',
  DELETE_MESSAGE: 'delete_message',
  DELETE_CHAT: 'delete_chat',
  UPDATE_MESSAGE: 'update_message',
  SEARCH_CHAT: 'search_chat',
  SEARCH_MESSAGES: 'search_messages',
  TOKEN: 'token',
  SEND_MESSAGE: 'send_message',
  SEARCH_USERS: 'search_user',
}
