import {observer} from 'mobx-react-lite'
import styles from './LeftChatsPanel.module.scss'
import UserCart from '../UserCart/UserCart'
import { chatStore } from 'Stores/ChatStore'
import { UserChatI } from 'Interfaces/chatinterface'
import { useState } from 'react'
import { userStore } from 'Stores/UserStore'
import { WEBSOCKET_MSG } from '../../../../Const'
import { websocketStore } from 'Stores/WebsocketStore'


const LeftChatPanel = observer(() => {
  const [searchUser, setSearchUser] = useState('')
  return (
    <div className={styles.left__panel} >
      {chatStore.castomContextUserMenuVis ?
      <div
        className={styles.scroll__visible__block}
        onContextMenu={(event) => {
          event.preventDefault()
          chatStore.setCastomContextMenuUserVis(false)
        }}
        onClick={() => chatStore.setCastomContextMenuUserVis(false)}></div>: ''}

      <div className={styles.panel__search}>
        <button
          className={styles.panel__search__button}
          onClick={() => {
            if (searchUser) {
              chatStore.searchUser(searchUser)
            } else {
              websocketStore.sendMessage(WEBSOCKET_MSG.USER_CHATS)
            }
          }}
        />
        <input
          type="text"
          placeholder="Поиск всех пользователей"
          className={styles.panel__search__input}
          onChange={(e) => {
            setSearchUser(e.target.value)
            chatStore.searchUser(e.target.value)
          }}
          value={searchUser}
        />

      </div>
      <div className={styles.user__list__cart}>
        {chatStore.user_chats?.map((data:UserChatI, index) => {
          return (
            <UserCart {...data} key={index}/>
          )
        })}

      </div>
    </div>
  )
})

export default LeftChatPanel
