import userLogo from 'style/img/UserIcon.svg'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Footer, Loading, CompletedCourse, Achievement, Input } from 'components/index'
import { userStore, websocketStore } from 'Stores/index'
import { BASE_URL } from 'consturl'
import { CompletedCourseI, AchievementI } from 'Interfaces/index'
import styles from './AnotherProfile.module.scss'
import { WEBSOCKET_MSG } from 'Const'

const AnotherProfile = observer(() => {
  const navigate = useNavigate()
  const [profileView, setProfileView] = useState(true)
  const user_id = useParams()

  useEffect(() => {
    if (userStore.online) {
      websocketStore.sendMessage(
        WEBSOCKET_MSG.ANOTHER_PROFILE,
        Number(user_id.id),
      )
    }
  }, [userStore.online])

  return (
    <>
      {/* Анимация загрузки */}
      {userStore.loading ?
        <Loading /> : (
        <>
          <section className={styles.profile__top__image}>
            <div className={styles.profile__top__image__content__wrapper}>
              <h2 className={styles.profile__top__image__text}>
                Пройди задания <br /> и стань IT-специалистом мирового уровня
              </h2>
            </div>
            <div className={styles.user__profile__tool__buttons}>
              <button
                onClick={() => setProfileView(true)}
                className={
                  profileView
                    ? `${styles.user__profile__tool__button} ${styles.active}`
                    : styles.user__profile__tool__button
                }
              >
                Профиль
              </button>
              <button
                onClick={() => setProfileView(false)}
                className={
                  profileView
                    ? styles.user__profile__tool__button
                    : `${styles.user__profile__tool__button} ${styles.active}`
                }
              >
                Курсы
              </button>
            </div>
          </section>

          <section className={styles.user__profile__wrapper}>
            <div className={styles.user__profile__content__wrapper}>
              {profileView ? (
                <div className={styles.user__profile}>
                  <div className={styles.left__userinfo__wrapper}>
                    <div className={styles.user__avatar__wrapper}>
                      <img
                        className={styles.user__avatar}
                        src={
                          userStore.AnotherProfile?.avatar_photo
                            ? `${BASE_URL}${userStore.AnotherProfile?.avatar_photo}`
                            : userLogo
                        }
                        alt="user-photo"
                      />
                      <p className={styles.user__rank}>
                        {userStore.AnotherProfile?.rank}
                      </p>
                    </div>
                    <div className={styles.online}>
                      <div
                        className={
                          userStore.AnotherProfile?.status
                            ? `${styles.status__circle} ${styles.online__color}`
                            : `${styles.status__circle} ${styles.not__online__color}`
                        }
                      />
                      {userStore.AnotherProfile?.status ? (
                        <p>В сети</p>
                      ) : (
                        <p>Не в сети</p>
                      )}
                    </div>
                  </div>
                  <form className={styles.userinfo__form} aria-readonly>
                    <Input
                      title="Имя"
                      inputType="text"
                      inputPlaceholder={`${userStore.AnotherProfile?.first_name} ${userStore.AnotherProfile?.last_name[0]}`}
                      labelClass="userinfo__label"
                      readonly={true}
                    />
                    <button
                      onClick={() => {
                        websocketStore.sendMessage(
                          WEBSOCKET_MSG.CHAT,
                          Number(user_id.id),
                        )
                        websocketStore.sendMessage(WEBSOCKET_MSG.USER_CHATS)
                        navigate('/chat')
                      }}
                    >
                      Начать чат
                    </button>
                  </form>
                  <div className={styles.user__achievements__wrapper}>
                    <h2 className={styles.user__achievements__title}>
                      Достижения
                    </h2>
                    <ul className={styles.user__achievements__list}>
                      {userStore.AnotherProfile?.achievements?.map(
                        (data: AchievementI) => {
                          return <Achievement {...data} key={data.name} />
                        },
                      )}
                    </ul>
                  </div>
                </div>
              ) : (
                <div className={styles.user__courses__block}>
                  <div className={styles.left__userinfo__wrapper}>
                    <div className={styles.user__avatar__wrapper}>
                      <img
                        className={styles.user__avatar}
                        src={
                          userStore.AnotherProfile?.avatar_photo
                            ? `${BASE_URL}${userStore.AnotherProfile?.avatar_photo}`
                            : userLogo
                        }
                        alt="user-photo"
                      />
                      <p className={styles.user__exp}>
                        {userStore.AnotherProfile?.exp} xp
                      </p>
                      <p className={styles.user__rank}>{userStore.user.rank}</p>
                    </div>
                  </div>
                  {userStore.started_courses_another_user?.length ? (
                    <ul className={styles.user__started__courses__list}>
                      {userStore.started_courses_another_user.map(
                        (data: CompletedCourseI) => {return <CompletedCourse {...data} key={data.id} />}
                      )}
                    </ul>
                  ) : (
                    <h2 className={styles.not__courses}>Курсов еще нет</h2>
                  )}
                </div>
              )}
            </div>
          </section>
        </>
      )}
    </>
  )
})

export default AnotherProfile
