import { observer } from "mobx-react-lite"
import styles from './PreviewSection.module.scss'

const PreviewSection = observer(() => {
    return (
        <section className={styles.preview__section}>
            <div className='container'>
              <div className={styles.preview__text__wrapper}>
                <div>
                  <h1 className={styles.preview__title}>Онлайн-курсы</h1>
                  <h2 className={styles.preview__title__text}>
                    по программированию
                  </h2>
                </div>
                <p className={styles.preview__text}>
                  Получите востребованную профессию, независимо
                  <br />
                  от прошлого опыта, специальности, образования и
                  <br />
                  навыков
                </p>
              </div>
            </div>
          </section>
    )
})

export default PreviewSection