import { SelectI } from 'Interfaces/helpInterfaces'
import styles from './Select.module.scss'
import { observer } from 'mobx-react-lite'

const Select = observer((data: SelectI) => {
  return (
    <label className={data.labelClass && styles[data.labelClass]}>
      {data.title && (
        <p className={data.titleClass && styles[data.titleClass]}>
          {data.title}
        </p>
      )}
      <select
        className={data.selectClass && styles[data.selectClass]}
        name={data.selectName}
        defaultValue={data.selectDefaultValue}
      >
        {data.options.map(option => {
          return (
            <option
              className={data.optionsClass && styles[data.optionsClass]}
              key={option.value}
              value={option.value}
            >
              {option.name}
            </option>
          )
        })}
      </select>
    </label>
  )
})
export default Select
