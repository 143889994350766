import { observer } from 'mobx-react-lite'
import { Footer } from 'components/index'
import { JavaScriptMockCartsNote } from '../Mock/JavaScriptMockData'
import { DocumentationI } from 'Interfaces/index'
import {
  TitleDocumentation,
  CartNote,
  Sides,
  SlideAdditionalInformation,
  Communication,
  MustDeveloper,
  Work,
} from '../components/index'
import styles from './JavaScript.module.scss'

const JavaScript = observer(() => {
  return (
    <div className={styles.javascript}>
      <div className="container">
        <div className={styles.javascript__content}>
          <TitleDocumentation
            name="JavaScript"
            description="Язык программирования, который используется для создания динамических и интерактивных веб-приложений. Он является основой для Frontend разработки и позволяет создавать адаптивные и отзывчивые пользовательские интерфейсы."
            id="1"
          />
          <section className={styles.note__section}>
            <h3>Примечание</h3>
            <div className={styles.note__list}>
              {JavaScriptMockCartsNote.map((data: DocumentationI, index) => {
                return <CartNote {...data} key={index} />
              })}
            </div>
          </section>
          <Work
            name="Frontend разработчики"
            description="Это специалисты, ответственные за создание и поддержку серверной части веб-приложений. Их работа связана с обработкой запросов, взаимодействием с базами данных, разработкой API (Application Programming Interface) и обеспечением функциональности, которая не видна конечному пользователю, но является необходимой для правильного функционирования приложения."
          />
          <hr />
          <section className={styles.frontend__section}>
            <MustDeveloper name="Основные обязанности Frontend разработчиков" />
            <Communication
              description="Cпециалисты, которые занимаются созданием пользовательского интерфейса веб-приложений. Их основная задача - обеспечить удобство использования и приятный внешний вид веб-приложения для конечного пользователя.
                          Frontend разработчики работают с HTML, CSS и JavaScript для создания интерактивных и отзывчивых пользовательских интерфейсов, которые могут включать в себя различные элементы, такие как кнопки, формы, меню, анимации и многое другое.
                          Это часть веб-приложения, с которой пользователь непосредственно взаимодействует. Он отображает информацию, предоставляет интерактивные элементы и обеспечивает пользовательский опыт.
                          Frontend разработка включает в себя использование HTML (HyperText Markup Language), CSS (Cascading Style Sheets) и JavaScript для создания интерфейса, который отображается в веб-браузере пользователя."
            />
          </section>
          <Sides />
          <SlideAdditionalInformation name="JavaScript" />
        </div>
      </div>
    </div>
  )
})

export default JavaScript
