export const JavaScriptMockCartsNote = [
  {
    name: 'Широкая распространенность и поддержка веб-браузерами',
    description:
      'JavaScript поддерживается практически всеми современными веб-браузерами, что делает его идеальным выбором для создания веб-приложений.',
  },
  {
    name: 'Создание высокоинтерактивных пользовательских интерфейсов',
    description:
      'JavaScript позволяет создавать интерфейсы, которые реагируют на действия пользователя и обеспечивают плавную и удобную навигацию. ',
  },
  {
    name: 'Большое количество фреймворков и библиотек',
    description:
      'JavaScript имеет обширную экосистему фреймворков и библиотек, которые упрощают и ускоряют процесс разработки.',
  },
  {
    name: 'Различия в поддержке функциональности между браузерами ',
    description:
      'Некоторые функции JavaScript могут работать по-разному в различных веб-браузерах, что может создавать проблемы совместимости и требовать дополнительных проверок и тестирования.',
  },
]

export const JavaScriptMockSidePlus = [
  {
    name: 'Универсальность',
    description:
      'JavaScript можно использовать как на стороне клиента, так и на стороне сервера (с использованием Node.js), что позволяет разрабатывать полноценные приложения на одном языке.',
  },
  {
    name: 'Интерактивность',
    description:
      'JavaScript позволяет создавать динамичные и интерактивные веб-страницы, улучшая пользовательский опыт за счет анимаций, валидации форм, AJAX-запросов и других возможностей.',
  },
  {
    name: 'Широкая поддержка',
    description:
      'Все современные браузеры поддерживают JavaScript, что делает его идеальным выбором для веб-разработки. Большинство сайтов и веб-приложений полагаются на JavaScript для выполнения клиентской логики.',
  },
  {
    name: 'Быстрое прототипирование',
    description:
      'JavaScript является динамически типизированным языком, что позволяет быстро писать и тестировать код без необходимости явного указания типов данных. Это ускоряет процесс разработки и прототипирования.',
  },
  {
    name: 'Большая экосистема и сообщество',
    description:
      'JavaScript имеет огромное сообщество разработчиков, а также множество библиотек и фреймворков, таких как React, Angular, Vue.js, которые упрощают разработку сложных веб-приложений.',
  },
  {
    name: 'Асинхронность',
    description:
      'JavaScript поддерживает асинхронное программирование с использованием колбэков, промисов и async/await, что упрощает работу с асинхронными операциями, такими как запросы к серверу или таймеры.',
  },
]

export const JavaScriptMockSideDis = [
  {
    name: 'Отсутствие типизации на уровне языка',
    description:
      'JavaScript является динамически типизированным языком, что может привести к ошибкам времени выполнения, которые трудно обнаружить и отладить. Например, переменная может изменить свой тип в процессе выполнения программы, что может вызвать неожиданные ошибки.',
  },
  {
    name: 'Проблемы с масштабируемостью',
    description:
      'JavaScript плохо подходит для больших проектов, так как отсутствие строгой типизации и модульности усложняет поддержку и масштабирование кода.3 Проблемы с производительностью',
  },
  {
    name: 'Проблемы с производительностью',
    description:
      'Хотя современные движки JavaScript (например, V8 от Google) значительно улучшили производительность, она все еще может быть ниже по сравнению с языками, компилируемыми в машинный код, такими как C++ или Rust, особенно при выполнении сложных вычислений.',
  },
  {
    name: 'Ограниченные возможности многопоточности',
    description:
      'JavaScript работает в однопоточном окружении, используя цикл событий для асинхронных операций. Это может быть ограничением при необходимости выполнения параллельных вычислений. Web Workers предоставляют некоторую многопоточность, но они сложны в использовании и имеют свои ограничения.',
  },
  {
    name: 'Разнородность стандартов',
    description:
      'Несмотря на наличие официального стандарта ECMAScript, различия в реализации и поддержке различных функций в браузерах могут привести к проблемам кроссбраузерной совместимости.',
  },
  {
    name: 'Безопасность',
    description:
      'JavaScript, выполняемый на стороне клиента, подвержен различным видам атак, таким как XSS (Cross-Site Scripting) и CSRF (Cross-Site Request Forgery). Хотя существуют методы защиты, они требуют дополнительных усилий и знаний от разработчиков.',
  },
]

export const JavaScriptMockAddInf = [
  {
    id: '1',
    name: 'React',
    description:
      'Библиотека JavaScript для создания пользовательских интерфейсов. Она позволяет разрабатывать масштабируемые и переиспользуемые компоненты интерфейса с использованием компонентной архитектуры.\n' +
      'Одной из ключевых особенностей React является использование виртуального DOM (Document Object Model), который позволяет оптимизировать производительность при обновлении пользовательского интерфейса.',
    settings: [
      {
        name: 'Виртуальный DOM для оптимизации производительности',
        description:
          'React использует виртуальный DOM для эффективного обновления пользовательского интерфейса, что позволяет создавать быстрые и отзывчивые приложения.',
      },
      {
        name: 'Компонентная архитектура',
        description:
          'Для удобного повторного использования кода React разделяет интерфейс на множество маленьких и переиспользуемых компонентов, что упрощает поддержку и масштабирование приложения.',
      },
    ],
  },
  {
    id: '2',
    name: 'TypeScript',
    description:
      'Язык программирования, созданный компанией Microsoft и впервые выпущенный в 2012 году. Он является строгой надстройкой над JavaScript и добавляет возможности статической типизации, что позволяет писать более надежный и понятный код.',
    settings: [
      {
        name: 'Зачем нужен TypeScript',
        description:
          'Создан для улучшения качества кода и повышения производительности разработчиков. Он помогает обнаруживать и устранять ошибки еще на этапе написания кода, а не во время его выполнения.\n' +
          'Это достигается за счет добавления типов данных к переменным, функциям и параметрам.',
      },
      {
        name: 'Статическая типизация',
        description:
          'TypeScript позволяет указывать типы данных для переменных и функций. Это помогает избежать ошибок, которые могут возникнуть из-за неправильного использования типов данных. Например, если вы пытаетесь передать строку в функцию, ожидающую число, TypeScript выдаст ошибку еще до выполнения программы.',
      },
      {
        name: 'Классы и интерфейсы',
        description:
          'Поддерживает объектно-ориентированное программирование, включая классы и интерфейсы. Это позволяет создавать более структурированный и поддерживаемый код. Интерфейсы позволяют описывать структуру объектов, что упрощает работу с ними.\n',
      },
      {
        name: 'Современные возможности',
        description:
          'TypeScript поддерживает все последние возможности JavaScript, включая функции ES6 и более поздних версий. Это позволяет использовать новейшие разработки и функции JavaScript, сохраняя при этом преимущества типизации.',
      },
      {
        name: 'Модули',
        description:
          'Поддерживает модульную структуру, что позволяет разбивать код на небольшие, управляемые части. Это упрощает разработку и поддержку крупных проектов.',
      },
    ],
  },
  {
    id: '3',
    name: 'MobX',
    description:
      'Это библиотека управления состоянием для JavaScript-приложений, разработанная Михейлом Вестерлу в 2015 году. Она предназначена для упрощения управления состоянием в сложных приложениях и автоматического обновления пользовательского интерфейса при изменении состояния.',
    settings: [
      {
        name: 'Зачем нужен MobX',
        description:
          'MobX создан для того, чтобы сделать управление состоянием в приложениях простым и предсказуемым. Он позволяет избежать большого количества шаблонного кода и обеспечивает автоматическую синхронизацию состояния и пользовательского интерфейса, что значительно упрощает процесс разработки и поддержки приложений.\n' +
          'MobX предлагает простой и интуитивно понятный API\n' +
          'Компоненты автоматически обновляются при изменении состояния, что снижает вероятность ошибок и упрощает поддержку кода',
      },
      {
        name: 'Меньше шаблонного кода',
        description:
          'Позволяет избежать большого количества шаблонного кода, что делает код более чистым и читаемым.',
      },
      {
        name: 'Гибкость',
        description:
          'MobX поддерживает различные подходы к управлению состоянием, что позволяет использовать его в различных сценариях.',
      },
    ],
  },
]
