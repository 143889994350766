import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { coursesStore, userStore } from 'Stores/index'
import { Button, Spinner, Loading } from 'components/index'
import Editor from './components/Editor/Editor'
import styles from './TaskPage.module.scss'

const TaskPage = observer(() => {
  const { course_id, task_id, user_id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    userStore.setFooter(true)
    if (
      userStore.user_header.is_staff &&
      window.location.href.includes(`user`)
    ) {
      coursesStore.getUserTask(Number(user_id), task_id)
    } else {
      coursesStore.getTask(task_id)
    }
    coursesStore.setConsoleOutput({})
    document.title = 'Задача'
    window.scrollTo(0, 0)
  }, [])

  function nextTaskRedirect() {
    if (coursesStore.consoleOutput.message?.success) {
      navigate(`/course/${course_id}/task/${Number(task_id) + 1}`)
    } else {
      navigate(`/course/${course_id}/task/${task_id}`)
    }
  }

  return (
    <>
      {userStore.loading ? (
        <Loading />
      ) : (
        <>
          <section className={styles.compiler}>
            <div className="container">
              <div className={styles.compiler__content__wrapper}>
                <div className={styles.compiler__task__theory}>
                  <h2 className={styles.compiler__task__theory__title}>
                    {coursesStore.courseTask.name_task}
                  </h2>
                  {coursesStore.courseTask.theory && (
                    <div
                      className={styles.compiler__task__theory__text}
                      dangerouslySetInnerHTML={{
                        __html: coursesStore.courseTask.theory,
                      }}
                    />
                  )}
                  <Link
                    to={`/course/${course_id}`}
                    className={styles.compiler__btn}
                  >
                    Назад
                  </Link>
                </div>
                <div className={styles.compiler__wrapper}>
                  <div className={styles.compiler__user__code}>
                    <Editor language={coursesStore.courseTask.language_type} />
                  </div>
                  <div className={styles.compiler__console}>
                    <div className={styles.compiler__console__title__wrapper}>
                      <p className={styles.compiler__console__title}>Консоль</p>

                      <div
                        className={styles.compiler__console__buttons__wrapper}
                      >
                        <Button
                          text={
                            coursesStore.loading ? <Spinner /> : 'Проверить'
                          }
                          onClick={() => coursesStore.postCode(task_id)}
                        />
                        <Button
                          text="Следующий"
                          onClick={nextTaskRedirect}
                        />
                      </div>
                    </div>
                    <hr />
                    <div className={styles.compiler__console__text}>
                      <p
                        className={`${styles.compiler__console__result} ${coursesStore.consoleOutput.message?.success ? styles.success : styles.failed}`}
                      >
                        {coursesStore.consoleOutput.message?.result}
                      </p>
                      {coursesStore.consoleOutput.message?.output?.map(
                        (text, index) => {
                          return <p key={index}>{text}</p>
                        },
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  )
})

export default TaskPage
