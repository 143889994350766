import { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { userStore, adminStore } from 'Stores/index'
import {
  Button,
  ButtonCloseForm,
  Input,
  Textarea,
  ButtonSetImage,
} from 'components/index'
import add__icon__dark from 'style/img/add-lesson-icon-dark.svg'
import add__icon__light from 'style/img/add-lesson-icon-light.svg'
import styles from './EditOrAddCourseForm.module.scss'
import NewLesson from 'pages/Admin/components/NewLesson/NewLesson'

const EditOrAddCourseForm = observer(() => {
  const editOrAddCourseForm = useRef<HTMLFormElement>(null)

  return (
    <>
      {(adminStore.isAddCourse || adminStore.isEditCourse) && (
        <section className={styles.add__course__block}>
          <h2 className={styles.add__course__block__title}>Добавить курс</h2>
          <form ref={editOrAddCourseForm} className={styles.add__course__form}>
            <ButtonCloseForm onClick={adminStore.closeCourseForm} />
            <div className={styles.add__course__inputs__wrapper}>
              <Input
                title="Название курса"
                titleClass="add__form__title"
                inputType="text"
                inputClass="add__form__input"
                inputName="name_course"
                inputDefaultValue={adminStore.name_course}
              />
              <ButtonSetImage
                title="Фото курса"
                titleClass="add__form__title"
                buttonName="Добавить"
                inputName="preview_image"
              />
              <Textarea
                title="Описание курса"
                titleClass="add__form__title"
                textareaClass="add__form__textarea"
                textareaName="detailed_description"
                defaultTextareaValue={adminStore.detailed_description}
              />
            </div>
            <div className={styles.add__course__body}>
              <p className={styles.lessons__title}>Уроки</p>
              <div className={styles.add__course__lessons__wrapper}>
                {adminStore.isEditCourse === false && (
                  <div
                    className={styles.add__lesson__button}
                    onClick={() =>
                      adminStore.setIsAddLesson(!adminStore.isAddLesson)
                    }
                  >
                    <img
                      className={styles.add__lesson__button__icon}
                      src={
                        userStore.userSiteTheme === 'dark'
                          ? add__icon__dark
                          : add__icon__light
                      }
                      alt="add-icon"
                    />
                  </div>
                )}
                {adminStore.isAddLesson && (
                  <div className={styles.new__lesson}>
                    <Input
                      inputType="text"
                      inputPlaceholder="Название урока"
                      inputClass="new__lesson__title__input"
                      inputOnChange={event =>
                        adminStore.setNewLessonTitle(event)
                      }
                    />
                    <button
                      className={styles.new__lesson__confirm__button}
                      onClick={event => adminStore.createLesson(event)}
                    />
                  </div>
                )}
                {adminStore.newLessons.map((newLesson, index) => {
                  return (
                    <NewLesson
                      key={index}
                      newLessonIndex={index}
                      thisNewLesson={newLesson}
                    />
                  )
                })}
              </div>
            </div>
            <div className={styles.add__course__buttons}>
              <Button
                text="Опубликовать"
                onClick={event =>
                  adminStore.publicNewCourse({
                    event,
                    editOrAddCourseForm: editOrAddCourseForm,
                  })
                }
              />
            </div>
          </form>
        </section>
      )}
    </>
  )
})

export default EditOrAddCourseForm
