import styles from './Footer.module.scss'
import '../../style/scss/app.scss'
import { observer } from 'mobx-react-lite'
import React from 'react'
import FooterBlock from 'components/Footer/components/FooterBlock/FooterBlock'

const Footer = observer(() => {

  return (
    <footer id="footer" className={styles.footer}>
      <div className="container">
        <div className={styles.footer__content__wrapper}>
          <FooterBlock
            title="HR поддержка"
            description="Вопросы по поступлению, трудоустройству или ассессменту:"
            href1="+7 855 575 90 24"
            href2="hr@alabuga.ru"
            href3="https://t.me/hr_alabuga_bot"
            text1="+7 855 575 90 24"
            text2="hr@alabuga.ru"
            text3="Чат в Telegram"
          />

          <FooterBlock
            title="Техническая поддержка"
            description="Вопросы по технической стороне бизнес-симуляции и HR-платформы"
            href1="+8 800 550 90 81"
            href2="hr.support@alabuga.ru"
            href3="https://t.me/hr_alabuga_bot"
            text1="8 800 550 90 81"
            text2="hr.support@alabuga.ru"
            text3="Чат в Telegram"
          />

          <FooterBlock
            title="Социальные сети"
            description="Будьте в курсе последних новостей и событий в ОЭЗ «Алабуга»:"
            href1="https://vk.com/sezalabuga"
            href2="https://www.youtube.com/@alabugapolytech"
            href3="https://dzen.ru/alabuga"
            text1="Наша группа «ВКонтакте»"
            text2="Наш канал на «YouTube»"
            text3="Наш канал на «Дзен»"
          />
        </div>
      </div>
    </footer>
  )
})

export default Footer
