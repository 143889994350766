import { makeAutoObservable } from 'mobx'
import { UserChatI, ChatI } from 'Interfaces/index'
import { websocketStore } from 'Stores/WebsocketStore'
import { WEBSOCKET_MSG } from '../Const'

class ChatStore {
  user_chats: UserChatI[] = []
  chat: ChatI = { chat: [] }
  xIndexClick = ''
  yIndexClick = ''
  settings?: number = -1
  messageId = -1
  updateMessage = false
  messageSenderId?: number = -1
  castomContextMenuVis = 0
  indexUpdateMessage = -1
  modalSettingsChat = false
  yIndexClickUser = ''
  xIndexClickUser = ''
  indexUpdateUsers = -1
  castomContextUserMenuVis = false
  userCartId = -1
  constructor() {
    makeAutoObservable(this)
  }

  setMessageSenderId(value?: number) {
    this.messageSenderId = value
  }

  setMessageId(id: number) {
    this.messageId = id
  }

  setUserChats(value: UserChatI[]) {
    this.user_chats = value
  }
  setXIndexClickMessage(value: string) {
    this.xIndexClick = value
  }

  setCastomContextMenuUserVis(value: boolean) {
    this.castomContextUserMenuVis = value
    console.log(this.castomContextUserMenuVis);
    
  }

  setYIndexClickMessage(value: string) {
    this.yIndexClick = value
  }

  setChat(value: ChatI) {
    this.chat = value
  }

  setSettings(value?: number) {
    this.settings = value
  }

  setUpdateMessage(value: boolean) {
    this.updateMessage = value
  }

  setCastomContextMenuVis(value: number) {
    this.castomContextMenuVis = value
  }

  setIndexUpdateMessage(value: number) {
    this.indexUpdateMessage = value
  }

  setModalSettingsChat(value: boolean) {
    this.modalSettingsChat = value
  }

  setYIndexClickUser(value: string) {
    this.yIndexClickUser = value
  }

  setXIndexClickUser(value: string) {
    this.xIndexClickUser = value
  }

  setUserCartId(value: number) {
    this.userCartId = value
  }

  pushUpdateMessage(text: string) {
    this.chat.chat[this.indexUpdateMessage].text = text
  }

  setSearchIndexUpdateMessage() {
    for (let i = 0; i < this.chat?.chat?.length; i += 1) {
      if (this.chat.chat[i].id === this.messageId) {
        this.setIndexUpdateMessage(i)
      }
    }
  }

  pushIndexUpdateUsers(id: number) {
    for (let i = 0; i < this.user_chats.length; i += 1) {
      if (this.user_chats[i].id === id) {
        this.indexUpdateUsers = i
      }
    }
  }

  searchUser(user: string) {
    const newUsersList: UserChatI[] = []
    if(user) {
      for (let i = 0; i < this.user_chats.length; i++) {
        if (this.user_chats[i].interlocutor.first_name.includes(user)) {
          newUsersList.push(this.user_chats[i])
        }
      }
      this.user_chats = newUsersList
    } else {
      websocketStore.sendMessage(WEBSOCKET_MSG.USER_CHATS)
    }

  }
}

export const chatStore = new ChatStore()
