import { observer } from 'mobx-react-lite'
import styles from './NewTaskString.module.scss'
import { adminStore } from 'Stores/AdminStore'
import { NewTaskStringI } from 'Interfaces/adminInterfaces'

const NewTaskString = observer((data: NewTaskStringI) => {
  const { lessonIndex, thisTaskStringIndex } = data

  return (
    <div className={styles.admin__task__string}>
      {!adminStore.isAddOrEditTask && !adminStore.isEditCourse && (
        <div className={styles.new__taskstring__toolbar}>
          <button
            onClick={event => {
              event.preventDefault()
              adminStore.deleteTaskString({
                lessonIndex,
                taskStringIndex: thisTaskStringIndex,
              })
            }}
            className={`${styles.delete__admin__task__string} ${styles.new__taskstring__tool__button}`}
          ></button>
          <button
            onClick={event =>
              adminStore.startAddNewTask({
                event,
                lessonIndex,
                taskStringIndex: thisTaskStringIndex,
              })
            }
            className={`${styles.add__new__task__on__taskstring} ${styles.new__taskstring__tool__button}`}
          ></button>
        </div>
      )}
      <ul className={styles.admin__taskstring__tasks__wrapper}>
        {adminStore.printNewTasks({
          lessonIndex,
          taskStringIndex: thisTaskStringIndex,
        })}
      </ul>
    </div>
  )
})

export default NewTaskString
