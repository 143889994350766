import { observer } from "mobx-react-lite";
import styles from './PreviewSection.module.scss'
import bgReg from 'style/img/BackgroundRegister.png'
import logo from 'style/img/LogoAlabuga.svg'
import { REG_TXT } from "pages/Entrance/Register/RegMock/RegMock";

const PreviewSection = observer(() => {
    const { title, preTitle } = REG_TXT
    return (
        <div className={styles.title}>
            <div className={styles.title__logo}>
                <img src={logo} alt="logo" />
                <h1>{title}</h1>
            </div>
            <h2 className={styles.title__inf}>{preTitle}</h2>
            <img src={bgReg} alt="bg" className={styles.bgReg} />
        </div>
    )
})
export default PreviewSection