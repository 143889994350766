import { FC } from 'react'
import { TheoryChoiceI } from 'Interfaces/index'
import style from './choice.module.scss'

export const RadioChoice: FC<TheoryChoiceI> = props => {
  return (
    <label className={style.choice__text}>
      <input
        className={style.choice__input_radio}
        type="radio"
        name="choice"
        value={props.value}
        onChange={props.onChange}
      />
      {props.choice}
    </label>
  )
}
