import { observer } from 'mobx-react-lite'
import styles from 'pages/Chat/components/Message/Message.module.scss'
import { chatStore } from 'Stores/ChatStore'
import { websocketStore } from 'Stores/WebsocketStore'
import { WEBSOCKET_MSG } from '../../../../Const'

const ContextMenu = observer(() => {
  return (
    <div
      className={styles.setting__message__panel}
      style={{
        left: `${chatStore.xIndexClick}`,
        top: `${chatStore.yIndexClick}`,
      }}
    >
      <button
        onClick={() => {
          websocketStore.sendMessage(
            WEBSOCKET_MSG.DELETE_MESSAGE,
            chatStore.messageId,
          )
          chatStore.setCastomContextMenuVis(0)
        }}
      >
        Удалить
      </button>
      {chatStore.messageSenderId !== chatStore.chat.interlocutor?.id && (
        <button
          onClick={() => {
            chatStore.setUpdateMessage(!chatStore.updateMessage)
            chatStore.setCastomContextMenuVis(0)
          }}
        >
          Редактировать
        </button>
      )}
    </div>
  )
})

export default ContextMenu
