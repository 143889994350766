import { InputI } from 'Interfaces/helpInterfaces'
import styles from './Input.module.scss'

export default function Input(data: InputI) {
  return (
    <label className={data.labelClass && styles[data.labelClass]}>
      {data.title && (
        <p className={data.titleClass && styles[data.titleClass]}>
          {data.title}
        </p>
      )}
      <input
        className={data.inputClass && styles[data.inputClass]}
        type={data.inputType}
        defaultValue={data.inputDefaultValue}
        placeholder={data.inputPlaceholder}
        name={data.inputName}
        readOnly={data.readonly}
        onChange={data.inputOnChange}
        onBlur={data.onBlur}
      />
    </label>
  )
}
