import { observer } from "mobx-react-lite";
import styles from './RegSidebar.module.scss'
import { EntranceInputI } from "Interfaces/userInterfaces";
import { REG_INPUTS } from "pages/Entrance/Register/RegMock/RegMock";

import { Button } from "components/index";
import { Link } from "react-router-dom";
import { REG_TXT } from "pages/Entrance/Register/RegMock/RegMock";
import { entranceStore } from "Stores/EntranceStore";
import { FormsRegI } from "Interfaces/helpInterfaces";
import EntranceInput from "pages/Entrance/components/EntranceInput/EntranceInput";


const RegSidebar = observer(() => {
    const { regTitle, regBtn, authTxt, authLink } = REG_TXT
    const { firstName, lastName, email, password, password2 } = entranceStore
    const form: FormsRegI = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        password: password,
        password2: password2
    }

    return (
        <div className={styles.sidebar}>
            <div className={styles.sidebar__form}>
                <h2>{regTitle}</h2>
                {REG_INPUTS.map((input: EntranceInputI, index) => {
                    return (<EntranceInput {...input} key={index} />)
                })}
                <Button text={regBtn} className={styles.reg__btn} onClick={() => entranceStore.register(form)} />
                <div className={styles.authorization}>
                    <p className={styles.authorization__text}>{authTxt}</p>
                    <Link to='/login'><p className={styles.authorization__link}>{authLink}</p></Link>
                </div>
            </div>
        </div>
    )
})
export default RegSidebar