import { observer } from 'mobx-react-lite'
import styles from './ContextMenuUser.module.scss'
import { chatStore } from 'Stores/ChatStore'
import { websocketStore } from 'Stores/WebsocketStore'
import { WEBSOCKET_MSG } from '../../../../Const'

const ContextMenuUser = observer(() => {
  return (
    <div
      className={styles.modal__settings__chat}
      style={{
        top: chatStore.yIndexClickUser,
        left: chatStore.xIndexClickUser,
      }}
    >
      <button
        onClick={() => {
          websocketStore.sendMessage(
            WEBSOCKET_MSG.DELETE_CHAT,
            '',
            chatStore.userCartId,
          )
        }}
      >
        Удалить чат
      </button>
    </div>
  )
})

export default ContextMenuUser
