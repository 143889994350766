import { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import { Input, Select, Textarea } from 'components/index'
import confirmIconBlue from 'style/img/confirm-icon-blue.svg'
import confirmIconGreen from 'style/img/confirm-icon-green.svg'
import closeCrossIcon from '../../img/close-cross-icon.svg'
import { userStore, adminStore } from 'Stores/index'
import { EditOrAddTaskFormI } from 'Interfaces/index'
import styles from './EditOrAddNewTaskForm.module.scss'

const EditOrAddTaskForm = observer((data: EditOrAddTaskFormI) => {
  const editOrAddTaskForm = useRef<HTMLFormElement>(null)
  const { editableTask, taskIndex, lessonIndex, taskStringIndex } = data

  return (
    <>
      {adminStore.isAddOrEditTask && (
        <form
          ref={editOrAddTaskForm}
          className={styles.add__task__and__taskstring__wrapper}
        >
          <Input
            title='Название задачи'
            inputType='text'
            inputClass='add__task__input'
            inputName='name_task'
            inputDefaultValue={editableTask?.name_task}
            labelClass='add__task__label'
          />
          <Input
            title='XP'
            inputType='number'
            inputClass='add__task__input'
            inputName='xp'
            inputDefaultValue={String(editableTask?.xp)}
            labelClass='add__task__label'
          />
          <Select
            options={adminStore.arrayOfCompexities}
            selectClass='add__task__select'
            title='Сложность'
            selectDefaultValue={editableTask?.complexity}
            selectName='complexity'
            labelClass='add__task__label'
          />
          <Select
            options={adminStore.arrayOfLanguageTypes}
            selectClass='add__task__select'
            title='Язык программирования'
            selectDefaultValue={editableTask?.language_type}
            selectName='language_type'
            labelClass='add__task__label'
          />
          <Textarea
            title='Input'
            textareaClass='add__task__textarea'
            textareaName='input'
            defaultTextareaValue={editableTask?.input}
            labelClass='add__task__label'
          />
          <Textarea
            title='Output'
            textareaClass='add__task__textarea'
            textareaName='output'
            defaultTextareaValue={editableTask?.output}
            labelClass='add__task__label'
          />
          <Input
            title='Name func'
            inputType='text'
            inputClass='add__task__input'
            inputName='name_func'
            inputDefaultValue={editableTask?.name_func}
            labelClass='add__task__long__label'
          />
          <Textarea
            title='Auto test'
            textareaClass='add__task__theory__textarea'
            textareaName='auto_test'
            defaultTextareaValue={editableTask?.auto_test}
            labelClass='add__task__label'
          />
          <Textarea
            title='Теория'
            textareaClass='add__task__theory__textarea'
            textareaName='theory'
            defaultTextareaValue={editableTask?.theory}
            labelClass='add__task__label'
          />
          <button
            className={styles.close__button}
            onClick={adminStore.closeNewTaskForm}
          >
            <img src={closeCrossIcon} alt='close-icon' />
          </button>
          <button
            className={styles.add__task__button}
            onClick={event =>
              adminStore.createOrChangeTask({
                event,
                editOrAddTaskForm,
                editableTask,
                taskIndex,
                lessonIndex,
                taskStringIndex
              })
            }
          >
            <img
              src={
                userStore.userSiteTheme === 'dark'
                  ? confirmIconGreen
                  : confirmIconBlue
              }
              alt='save-icon'
            />
          </button>
        </form>
      )}
    </>
  )
})

export default EditOrAddTaskForm
