import { observer } from 'mobx-react-lite'
import styles from './LeftCourseLine.module.scss'
import { TaskStringDataI } from 'Interfaces/coursesInterfaces'
import { coursesStore } from 'Stores/CourseStore'

const LeftCourseLine = observer((data:TaskStringDataI) => {

  return (
    <div className={styles.left__line}>
      <div
        className={styles.left__line__square}
      />
      <hr />
    </div>
  )
})

export default LeftCourseLine
