import { ChangeEvent, useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import {
  CheckboxChoice,
  RadioChoice,
  ErrorComponent,
  SuccessComponent,
} from './components/index'
import { Button, Spinner } from 'components/index'
import { userStore, coursesStore } from 'Stores/index'
import style from './TestPage.module.scss'

export const TestPage = observer(() => {
  const { course_id, task_id, user_id } = useParams()
  const navigate = useNavigate()

  const [selectedChoices, setSelectedChoices] = useState<number[]>([])

  useEffect(() => {
    if (
      userStore.user_header.is_staff &&
      window.location.href.includes(`user`)
    ) {
      coursesStore.getUserTask(Number(user_id), task_id)
      coursesStore.setCompletedTask(null)
    } else {
      coursesStore.getTask(task_id)
    }
    coursesStore.setConsoleOutput({})
    document.title = 'Теория'
    window.scrollTo(0, 0)
  }, [])

  const handleChoiceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { type, value, checked } = event.target
    if (type === 'radio') {
      setSelectedChoices([Number(value)])
    } else if (type === 'checkbox') {
      if (checked) {
        setSelectedChoices(prev => [...prev, Number(value)])
      } else {
        setSelectedChoices(prev =>
          prev.filter(choice => Number(choice) != Number(value)),
        )
      }
    }
  }

  const handleSubmit = (event: ChangeEvent<HTMLFormElement>) => {
    event.preventDefault()
    coursesStore.createAnswer(selectedChoices, Number(task_id))
  }

  return (
    <>
      <section className={style.compiler}>
        <div className="container">
          <div className={style.compiler__content__wrapper}>
            <div className={style.compiler__task__theory}>
              <h2 className={style.compiler__task__theory__title}>
                {coursesStore.courseTask.name_task}
              </h2>
              {coursesStore.courseTask.theory && (
                <div
                  className={style.compiler__task__theory__text}
                  dangerouslySetInnerHTML={{
                    __html: coursesStore.courseTask.theory,
                  }}
                />
              )}
            </div>

            {coursesStore.courseTask.theoretical_base?.type_choices.toLowerCase() ===
            'radio' ? (
              <p className={style.compiler__theory__subtitle}>
                Выберете правильный ответ
              </p>
            ) : (
              <p className={style.compiler__theory__subtitle}>
                Выберете правильный ответ (их может быть несколько):
              </p>
            )}

            <form className={style.compiler__wrapper} onSubmit={handleSubmit}>
              {coursesStore.completedTask == null ? (
                ''
              ) : coursesStore.completedTask ? (
                <SuccessComponent />
              ) : (
                <ErrorComponent />
              )}

              {coursesStore.courseTask.theoretical_base?.type_choices.toLowerCase() ===
              'radio' ? (
                <>
                  {coursesStore.courseTask.theoretical_base?.choices.map(
                    item => (
                      <RadioChoice
                        key={item.id}
                        value={Number(item.id)}
                        onChange={handleChoiceChange}
                        choice={item.choice}
                      />
                    ),
                  )}
                </>
              ) : (
                <>
                  {coursesStore.courseTask.theoretical_base?.choices.map(
                    item => (
                      <CheckboxChoice
                        choice={item.choice}
                        key={item.id}
                        name={`choice-${item.id}`}
                        value={Number(item.id)}
                        onChange={handleChoiceChange}
                      />
                    ),
                  )}
                </>
              )}

              <div className={style.compiler__console__buttons__wrapper}>
                <Button
                  type="submit"
                  text={coursesStore.loading ? <Spinner /> : 'Проверить'}
                  className={style.compiler__btn}
                />
                <Link
                  to={`/course/${course_id}/testing/${Number(task_id) + 1}`}
                  className={style.compiler__link}
                >
                  Следующий
                </Link>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  )
})
