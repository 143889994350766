import styles from './ButtonNavigate.module.scss'
import { userStore } from 'Stores/UserStore'
import { ButtonHeaderPanelI } from 'Interfaces/helpInterfaces'

export default function ButtonNavigate(data: ButtonHeaderPanelI) {
  return (
    <button
      onClick={data.onClick}
      className={`${styles.user__panel__button} ${userStore.userSiteTheme === 'dark' ? styles.dark : styles.light}`}
    >
      <img src={data.image} alt="hr-icon" />
      {data.text}
    </button>
  )
}
