import { observer } from 'mobx-react-lite'
import styles from './Notification.module.scss'
import { BASE_URL } from '../../../../consturl'
import { NotificationsI } from 'Interfaces/userInterfaces'
import { Link } from 'react-router-dom'

const Notification = observer((data: NotificationsI) => {
  return (
    <div className={styles.notification}>
      <div className={styles.logo__notification}>
        <img src={`${BASE_URL}${data.notification_photo}`} alt="" />
      </div>
      <div className={styles.text__notification}>
        <h3>{data.title}</h3>
        <p className={styles.description__notification}>{data.text}</p>
        {data.type !== 'tech_work' && data.type === 'achievement' && 'rank' ? (
          <Link to="/profile">
            <p className={styles.go__notification__button}>Перейти</p>
          </Link>
        ) : (
          <Link to="/">
            <p className={styles.go__notification__button}>Перейти</p>
          </Link>
        )}
      </div>
    </div>
  )
})

export default Notification
