import { observer } from 'mobx-react-lite'
import { coursesStore } from 'Stores/CourseStore'
import styles from './AnswerModal.module.scss'
import { ChoicesI } from 'Interfaces/coursesInterfaces'

interface AnswerModalI {
  code_area?: string
  answers: ChoicesI[]
}

export const AnswerModal = observer((data: AnswerModalI) => {
  const exitFromTable = () => {
    coursesStore.isOpenAnswer = -1
  }

  return (
    <div className={styles.answer__bg} onClick={() => exitFromTable()}>
      <div className={styles.answer} onClick={e => e.stopPropagation()}>
        <p className={styles.answer__close} onClick={() => exitFromTable()}>
          Закрыть
        </p>
        {data.code_area === null
          ? data.answers.map(item => <p key={item.id}> · {item.choice}</p>)
          : data.code_area}
      </div>
    </div>
  )
})
