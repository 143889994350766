import { UserCartSidebar } from 'components/Sidebar/components/UserCartSidebar/UserCartSidebar'
import SendMessage from 'pages/Chat/components/SendMessage/SendMessage'
import SelectChat from 'pages/Chat/components/SelectChat/SelectChat'
import HeaderChat from 'pages/Chat/components/HeaderChat/HeaderChat'
import { ChatMessageI, UserChatI } from 'Interfaces/chatinterface'
import Message from 'pages/Chat/components/Message/Message'
import { websocketStore } from 'Stores/WebsocketStore'
import { chatStore } from 'Stores/ChatStore'
import styles from './MenuChat.module.scss'
import { observer } from 'mobx-react-lite'
import React, { useEffect } from 'react'
import { WEBSOCKET_MSG } from 'Const'

export const MenuChat = observer(() => {
  useEffect(() => {
    if (chatStore.user_chats) {
      websocketStore.sendMessage(WEBSOCKET_MSG.USER_CHATS)
    }
  }, [])
  return (
    <div className={styles.menu__chat}>
      <div className={styles.user__list}>
        {chatStore.user_chats?.map((data: UserChatI, index) => {
          return <UserCartSidebar {...data} key={index} />
        })}
      </div>
      {chatStore.chat.chat ?
        <div className={styles.chat}>
          <HeaderChat />
          <div className={styles.chat__panel}>
            <div className={styles.message__list}>
              {chatStore.chat.chat?.map((data: ChatMessageI, index) => {
                return <Message {...data} key={index} />
              })}
            </div>
            <SendMessage />
          </div>
        </div>
        : <SelectChat/>}
    </div>
  )
})
