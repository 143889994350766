import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { ButtonCourse } from 'components/index'
import { CourseCuttedI } from 'Interfaces/index'
import { userStore, adminStore, coursesStore } from 'Stores/index'
import { BASE_URL } from 'consturl'
import styles from './Course.module.scss'
import cn from 'classnames'

const Course = observer((data: CourseCuttedI) => {
  const navigate = useNavigate()
  const { id, preview_image, name_course } = data



  return (
    <li
      className={cn(styles.course, {
        [styles.light]:userStore.userSiteTheme === 'light'
      })}
      onClick={() => {
        if(coursesStore.btnCourseHoverEvent) {
          navigate('/course/' + id)
        }
      }}
    >
      <img
        src={BASE_URL + preview_image}
        alt='course-image'

      />
      {userStore.user_header.is_staff && (
        <div className={styles.course__admin__button__wrapper}>
          <ButtonCourse
            text="Редактировать"
            onClick={() => adminStore.editCourse({ navigate, id })}
          />
        </div>
      )}
      <h2
        className={styles.course__name}

      >
        {name_course}
      </h2>
    </li>
  )
})

export default Course
