import styles from './Profile.module.scss'
import userLogo from '../../../style/img/UserIcon.svg'
import { useEffect, useState } from 'react'
import { userStore } from 'Stores/UserStore'
import { observer } from 'mobx-react-lite'
import Loading from '../../../components/Loading/Loading'
import { BASE_URL } from '../../../consturl'
import CompletedCourse from '../../../components/CompletedCourse/CompletedCourse'
import { CompletedCourseI } from 'Interfaces/coursesInterfaces'
import { AchievementI } from 'Interfaces/userInterfaces'
import Achievement from '../../../components/Achievement/Achievement'
import Input from '../../../components/Input/Input'

const Profile = observer(() => {
  const [profileView, setProfileView] = useState(true)

  useEffect(() => {
    document.title = 'Профиль'
    userStore.get_user()
    userStore.setFooter(true)
  }, [])

  return (
    <>
      {/* Анимация загрузки */}
      {userStore.loading ? (
        <Loading />
      ) : (
        <>
          <section className={styles.profile__top__image}>
            <div className={styles.profile__top__image__content__wrapper}>
              <h2 className={styles.profile__top__image__text}>
                Пройди задания <br /> и стань IT-специалистом мирового уровня
              </h2>
            </div>
            <div className={styles.user__profile__tool__buttons}>
              <button
                onClick={() => setProfileView(true)}
                className={
                  profileView
                    ? `${styles.user__profile__tool__button} ${styles.active}`
                    : styles.user__profile__tool__button
                }
              >
                Профиль
              </button>
              <button
                onClick={() => setProfileView(false)}
                className={
                  profileView
                    ? styles.user__profile__tool__button
                    : `${styles.user__profile__tool__button} ${styles.active}`
                }
              >
                Мои курсы
              </button>
            </div>
          </section>

          <section className={styles.user__profile__wrapper}>
            <div className={styles.user__profile__content__wrapper}>
              {profileView ? (
                <div className={styles.user__profile}>
                  <div className={styles.left__userinfo__wrapper}>
                    <div className={styles.user__avatar__wrapper}>
                      <img
                        className={styles.user__avatar}
                        src={
                          userStore.user_header.avatar_photo
                            ? `${BASE_URL}${userStore.user_header.avatar_photo}`
                            : userLogo
                        }
                        alt="user-photo"
                      />
                      <p className={styles.user__rank}>{userStore.user.rank}</p>
                    </div>
                    <div className={styles.online}>
                      <div
                        className={
                          userStore.online
                            ? `${styles.status__circle} ${styles.online__color}`
                            : `${styles.status__circle} ${styles.not__online__color}`
                        }
                      />
                      {userStore.online ? <p>В сети</p> : <p>Не в сети</p>}
                    </div>
                  </div>
                  <form className={styles.userinfo__form} aria-readonly>
                    <Input
                      title="Имя"
                      inputType="text"
                      inputPlaceholder={userStore.user_header.first_name}
                      labelClass="userinfo__label"
                      readonly={true}
                    />
                    <Input
                      title="Фамилия"
                      inputType="text"
                      inputPlaceholder={userStore.user_header.last_name}
                      labelClass="userinfo__label"
                      readonly={true}
                    />
                    <Input
                      title="Электронная почта"
                      inputType="email"
                      inputPlaceholder={userStore.user.email}
                      labelClass="userinfo__label"
                      readonly={true}
                    />
                  </form>
                  <div className={styles.user__achievements__wrapper}>
                    <h2 className={styles.user__achievements__title}>
                      Мои достижения
                    </h2>
                    <ul className={styles.user__achievements__list}>
                      {userStore.achievement.map((data: AchievementI) => {
                        return <Achievement {...data} key={data.name} />
                      })}
                    </ul>
                  </div>
                </div>
              ) : (
                <div className={styles.user__courses__block}>
                  <div className={styles.left__userinfo__wrapper}>
                    <div className={styles.user__avatar__wrapper}>
                      <img
                        className={styles.user__avatar}
                        src={
                          userStore.user_header.avatar_photo
                            ? `${BASE_URL}${userStore.user_header.avatar_photo}`
                            : userLogo
                        }
                        alt="user-photo"
                      />
                      <p className={styles.user__exp}>
                        {userStore.user_header.exp} xp
                      </p>
                      <p className={styles.user__rank}>{userStore.user.rank}</p>
                    </div>
                  </div>
                  {userStore.started_courses.length ? (
                    <ul className={styles.user__started__courses__list}>
                      {userStore.started_courses.map(
                        (data: CompletedCourseI) => {
                          return <CompletedCourse {...data} key={data.id} />
                        },
                      )}
                    </ul>
                  ) : (
                    <h2 className={styles.not__courses}>Курсов еще нет</h2>
                  )}
                </div>
              )}
            </div>
          </section>
        </>
      )}
    </>
  )
})

export default Profile
