import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { AchievementI } from 'Interfaces/index'
import { BASE_URL } from 'consturl'
import { adminStore, userStore } from 'Stores/index'
import achievmentI from 'style/img/achivmentDefault.svg'
import styles from './Achievement.module.scss'

const Achievement = observer((data: AchievementI) => {
  const { id, index, achievement_photo, name, description } = data
  const [isEditable, setIsEditable] = useState(true)

  useEffect(() => {
    if (
      userStore.user_header.is_staff &&
      window.location.href.includes('/profile')
    ) {
      setIsEditable(false)
    }
  }, [])

  return (
    <li
      className={`${styles.achievement} ${userStore.userSiteTheme === 'light' && styles.light}`}
    >
      <img
        className={styles.achievement__photo}
        src={
          achievement_photo ? `${BASE_URL}${achievement_photo}` : achievmentI
        }
        alt="achievement-photo"
      />
      <div className={styles.achievement__text}>
        <p className={styles.achievement__title}>{data.name}</p>
        <p
          className={styles.achievement__description}
          dangerouslySetInnerHTML={{ __html: data.description }}
        ></p>
      </div>
      {userStore.user_header.is_staff && isEditable && (
        <div className={styles.achievement__admin}>
          <button
            className={styles.edit__btn}
            onClick={() =>
              adminStore.editAchievement({
                id,
                name,
                description,
              })
            }
          />
          <button
            className={styles.delete__btn}
            onClick={() => adminStore.deleteAchievement({ id, index })}
          />
        </div>
      )}
    </li>
  )
})
export default Achievement
