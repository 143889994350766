import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Footer } from 'components/index'
import {
  TitleDocumentation,
  CartNote,
  Sides,
  SlideAdditionalInformation,
  Communication,
  MustDeveloper,
  Work,
} from '../components/index'
import { PythonMockCartsNote } from '../Mock/PuthonMockData'
import { DocumentationI } from 'Interfaces/index'
import styles from './Python.module.scss'

const Python = observer(() => {
  useEffect(() => {
    document.title = 'Python'
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className={styles.python}>
      <div className="container">
        <div className={styles.python__content}>
          <TitleDocumentation
            name="Python"
            description="Python — универсальный и легко читаемый язык программирования, созданный «Гвидо ван  Россумом» в конце 1980-х годов и выпущенный в 1991 году. Он широко используется в различных областях, включая веб-разработку, науку о данных, машинное обучение, автоматизацию и разработку игр."
            id="2"
          />
          <section className={styles.note__section}>
            <h3>Примечание</h3>
            <div className={styles.note__list}>
              {PythonMockCartsNote.map((data: DocumentationI, index) => {
                return <CartNote {...data} key={index} />
              })}
            </div>
          </section>
          <Work
            name="Backend разработчики"
            description="Это специалисты, ответственные за создание и поддержку серверной части веб-приложений. Их работа связана с обработкой запросов, взаимодействием с базами данных, разработкой API (Application Programming Interface) и обеспечением функциональности, которая не видна конечному пользователю, но является необходимой для правильного функционирования приложения."
          />
          <hr />
          <section className={styles.backend__section}>
            <MustDeveloper name="Основные обязанности Backend разработчиков" />
            <Communication description="Backend разработчики работают в тесном взаимодействии с Frontend разработчиками, дизайнерами и другими членами команды разработки для создания полнофункциональных веб-приложений. Их работа направлена на обеспечение стабильной и эффективной работы серверной части приложения, что позволяет пользовательскому интерфейсу работать быстро и без сбоев" />
          </section>
          <Sides name="Python" />
          <SlideAdditionalInformation name="Python" />
        </div>
      </div>
    </div>
  )
})

export default Python
