import { useNavigate, useParams } from 'react-router-dom'
import { CompletedCourseI } from 'Interfaces/coursesInterfaces'
import { BASE_URL } from 'consturl'
import styles from './CompletedCourse.module.scss'
import { userStore } from 'Stores/UserStore'

function CompletedCourse(data: CompletedCourseI) {
  const { id } = useParams()
  const navigate = useNavigate()

  return (
    <li className={styles.started__course}>
      <div className={styles.started__course__info}>
        <h2>{data.course_name}</h2>

        <div className={styles.started__course__progress__info}>
          <div className={styles.started__course__progress}>
            <div
              className={styles.started__course__progress__line}
              style={{ width: `${data.completion_percentage}` }}
            ></div>
          </div>
          <p>{data.completion_percentage}</p>
          <p>
            {data.completed_task_count}/{data.task_count} заданий выполнено
            успешно
          </p>
        </div>
        <button
          onClick={
            userStore.user_header.is_staff
              ? () => navigate(`/course/${data.id}/user/${Number(id)}`)
              : userStore.user_header === data.id
                ? () => navigate(`/course/${data.id}`)
                : () => navigate(`/course/${data.id}`)
          }
          className={
            data.completion_percentage === '100.0%'
              ? styles.continue__course__button__completed
              : styles.continue__course__button
          }
        >
          {userStore.user_header.is_staff
            ? 'Посмотреть курс'
            : data.completion_percentage === '100.0%'
              ? 'Выполнено'
              : 'Продолжить курс'}
        </button>
      </div>
      <img src={`${BASE_URL}${data.preview_image}`} alt="course-icon" />
    </li>
  )
}

export default CompletedCourse
