import styles from './Head.module.scss'

export const Head = () => {
  return (
    <div className={styles.table__head}>
      <p className={styles.table__head_text}>Аватар</p>
      <p className={styles.table__head_text}>Фамилия</p>
      <p className={styles.table__head_text}>Имя</p>
      <p className={styles.table__head_text}>Почта</p>
      <p className={styles.table__head_text}>Код</p>
    </div>
  )
}
