import { observer } from "mobx-react-lite";
import programLogo from '../../img/course-info-image.png'
import styles from './PreviewSection.module.scss'
import { userStore, coursesStore } from 'Stores/index'
import { Button } from 'components/index'
import { useNavigate } from "react-router-dom";

const PreviewSection = observer(() => {
    const navigate = useNavigate()
    return (
        <section className={styles.course__description__wrapper}>
            <div className={`${styles.course__description__content__wrapper} container`}>
              <div className={styles.course__description}>
                <h2 className={styles.course__description__title}>
                  {coursesStore.course.name_course}
                </h2>
                <div
                  className={styles.course__description__text}
                  dangerouslySetInnerHTML={{
                    __html: coursesStore.course.detailed_description,
                  }}
                ></div>
                <div className={styles.btn__block}>
                  <div className={styles.btn__next__description}>
                    {coursesStore.course.name_course === 'Python' ? (
                      <Button
                        text="Подробнее о курсе"
                        onClick={() => navigate(`/python`)}
                      />
                    ) : (
                      coursesStore.course.name_course === 'JavaScript' && (
                        <Button
                          text="Подробнее о курсе"
                          onClick={() => navigate(`/javascript`)}
                        />
                      )
                    )}
                    <Button
                      text="Продолжить обучение"
                      onClick={() =>
                        navigate(`task/${coursesStore.last_completed_task}`)
                      }
                    />
                  </div>
                  {userStore.user_header.is_staff && (
                    <Button
                      text="Статистика"
                      className={styles.btn__stat}
                      onClick={() => navigate('statistics')}
                    />
                  )}
                </div>
              </div>
              <img
                alt="programmer"
                src={programLogo}
                className={styles.course__preview__image}
              />
            </div>
          </section>
    )
})

export default PreviewSection