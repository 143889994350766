import { EntranceInputI } from "Interfaces/userInterfaces";
import { observer } from "mobx-react-lite";
import styles from './EntranceInput.module.scss'

const EntranceInput = observer((input: EntranceInputI) => {
    return (
        <div className={styles.wrapp}>
            <label htmlFor="">{input.label}</label>
            <div className={styles.reg__input}>
                <img src={input.logo} alt="123" />
                <input
                    type="text"
                    onChange={(e) => input.onChange(e.target.value)}
                    placeholder={input.placeholder}
                />
            </div>
        </div>

    )
})

export default EntranceInput