import { observer } from 'mobx-react-lite'
import styles from './Commutication.module.scss'
import { DocumentationI } from 'Interfaces/coursesInterfaces'
import CommunicationImage from './img/Communication.png'

const Communication = observer((data: DocumentationI) => {
  return (
    <div className={styles.communication}>
      <div className={styles.communication__information}>
        <h2>Коммуникация в команде</h2>
        <p>{data.description}</p>
      </div>
      <img src={CommunicationImage} alt="" />
    </div>
  )
})

export default Communication
