import { ButtonI } from 'Interfaces/helpInterfaces'
import styles from './Button.module.scss'
import { observer } from 'mobx-react-lite'

const Button = observer((data: ButtonI) => {
  return (
    <button 
      onClick={data.onClick} 
      type={data.type} 
      className={[ data.className, styles.button].join(' ')} 
    >
      {data.text}
    </button>
  )
})
export default Button
