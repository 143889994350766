import userLogo from 'style/img/UserIcon.svg'
import { TopUserI } from 'Interfaces/userInterfaces'
import { useNavigate } from 'react-router-dom'
import styles from './RatingUser.module.scss'
import { BASE_URL } from 'consturl'

export default function RatingUser({ userdata }: { userdata: TopUserI }) {
  const navigate = useNavigate()
  return (
    <li
      onClick={() => {
        navigate(`/profile/${userdata.id}`)
      }}
      className={`${styles.rating__list__user} ${
        userdata.place === 1
          ? styles.rating__list__user__one__place
          : userdata.place === 2
            ? styles.rating__list__user__two__place
            : userdata.place === 3
              ? styles.rating__list__user__three__place
              : styles.rating__list__user__other
      }`}
    >
      {userdata.place < 4 ? (
        <div className={styles.top__places}>
          <div
            className={`${styles.top__places__place} ${
              userdata.place === 1
                ? styles.top__one__place
                : userdata.place === 2
                  ? styles.top__two__place
                  : styles.top__three__place
            }`}
          ></div>
        </div>
      ) : (
        <p className={styles.rating__user__place}>{userdata.place}</p>
      )}
      <div className={styles.rating__user}>
        <div className={styles.rating__user__image}>
          <img
            className={styles.rating__user__image}
            src={
              userdata.avatar_photo
                ? `${BASE_URL}${userdata.avatar_photo}`
                : userLogo
            }
            alt="user-icon"
          />
        </div>
        <div className={styles.rating__user__info}>
          <p className={styles.rating__user__info__name}>
            {userdata.last_name} {userdata.first_name[0]}
          </p>
          <p className={styles.rating__user__info__rank}>{userdata.rank}</p>
        </div>
      </div>
      <p className={styles.rating__user__info__exp}>{userdata.exp}xp</p>
    </li>
  )
}
