import { observer } from 'mobx-react-lite'
import styles from './HeaderChat.module.scss'
import { chatStore } from 'Stores/ChatStore'
import { BASE_URL } from '../../../../consturl'
import UserLogo from 'style/img/UserIcon.svg'
import { useState } from 'react'

const HeaderChat = observer(() => {
  const [modalSettings, setModalSettings] = useState(false)
  return (
    <header className={styles.header__chat}>
      <div className={styles.header__chat__interlocutor}>
        <img
          src={
            chatStore.chat.interlocutor?.avatar_photo
              ? BASE_URL + chatStore.chat.interlocutor?.avatar_photo
              : UserLogo
          }
          alt="userlogo"
        />
        <div className={styles.interlocutor__block}>
          <h2>
            {chatStore.chat.interlocutor?.first_name}{' '}
            {chatStore.chat.interlocutor?.last_name}
          </h2>

          {chatStore.chat.interlocutor?.status ? (
            <p>В сети</p>
          ) : (
            <p>Не в сети</p>
          )}
        </div>
      </div>
      <div
        className={styles.btn__menu__settings}
        onClick={() => setModalSettings(!modalSettings)}
      >
        <hr />
        <hr />
        <hr />
      </div>
      {modalSettings && (
        <div className={styles.modal__settings}>Очистить чат</div>
      )}
    </header>
  )
})

export default HeaderChat
