import { observer } from "mobx-react-lite";
import styles from './LessonSection.module.scss'
import { LessonCuttedI } from "Interfaces/coursesInterfaces";
import Lesson from "../Lesson/Lesson";
import { coursesStore } from "Stores/CourseStore";
const LessonSection = observer(() => {
    return(
        <section className={styles.course__lessons__section}>
            <div className="container">
            <div className={styles.lessons__list}>
                {coursesStore.course.lesson.map((lesson: LessonCuttedI) => {
                return <Lesson key={lesson.id} {...lesson} />
                })}
            </div>
            </div>
        </section>
    )
})

export default LessonSection