import { observer } from 'mobx-react-lite'
import styles from './SelectChat.module.scss'
import ChatImg from 'style/img/Chat.png'

const SelectChat = observer(() => {
  return (
    <div className={styles.select__chat}>
      <img src={ChatImg} alt="" />
      <p>Выбирите чат</p>
    </div>
  )
})

export default SelectChat
