export const PythonMockCartsNote = [
  {
    name: 'Веб-разработка',
    description:
      'Python используется для создания серверной части веб-приложений. Он часто используется вместе с фреймворками, такими как Django и Flask, для разработки мощных и эффективных веб-приложений.',
  },
  {
    name: 'Наука о данных',
    description:
      'Python является одним из самых популярных языков программирования для анализа данных и машинного обучения. Библиотеки, такие как NumPy, Pandas и TensorFlow, делают его идеальным выбором для обработки и анализа данных.',
  },
  {
    name: 'Машинное обучение',
    description:
      'Python предлагает богатые возможности для разработки алгоритмов машинного обучения и создания искусственного интеллекта. Библиотеки, такие как Scikit-learn и Keras, позволяют легко создавать и обучать модели машинного обучения.',
  },
  {
    name: 'Автоматизация',
    description:
      'Python часто используется для автоматизации рутинных задач и скриптинга. Его простота и удобство делают его идеальным выбором для написания скриптов',
  },
]

export const PythonMockSidePlus = [
  {
    name: 'Интуитивно понятный синтаксис',
    description:
      'Python - известен своей простотой и читаемостью кода, что делает его идеальным для начинающих программистов.\n' +
      'Этот язык ставит акцент на ясности и лаконичности, что облегчает понимание его конструкций даже без предварительного опыта.',
  },
  {
    name: 'Широкое сообщество и экосистема',
    description:
      'Python обладает огромным сообществом разработчиков, что привело к формированию обширной экосистемы библиотек и фреймворков. Это позволяет разработчикам использовать готовые решения для различных задач и быстро решать проблемы.',
  },
  {
    name: 'Поддержка различных парадигм программирования',
    description:
      'Python поддерживает разнообразные стили программирования, включая объектно-ориентированное, процедурное и функциональное программирование. Это позволяет разработчикам выбирать подход, который наилучшим образом соответствует их потребностям и предпочтениям.',
  },
  {
    name: 'Кроссплатформенность',
    description:
      'Python работает на различных операционных системах, включая Windows, MacOS и Linux. Это делает его универсальным инструментом разработки, который можно использовать на различных платформах без изменения кода.',
  },
  {
    name: 'Хорошая интеграция с другими языками',
    description:
      'Python легко интегрируется с другими языками программирования, такими как C/C++ и Java. Это позволяет разработчикам использовать библиотеки и компоненты, написанные на других языках, в своих Python-проектах без необходимости переписывать их с нуля.',
  },
]

export const PythonMockSideDis = [
  {
    name: 'Низкая скорость выполнения',
    description:
      'Python является интерпретируемым языком программирования, что делает его менее эффективным по сравнению с компилируемыми языками, такими как C или C++. Это может привести к более долгому времени выполнения программ.',
  },
  {
    name: 'Ограничения GIL',
    description:
      'GIL (Global Interpreter Lock) ограничивает выполнение многопоточных программ в Python, что может привести к проблемам с параллельным выполнением кода и использованием многопоточности для повышения производительности.',
  },
  {
    name: 'Неидеально для мобильных приложений',
    description:
      'Python имеет ограниченную поддержку для мобильной разработки по сравнению с другими языками, такими как Java или Kotlin для Android и Swift для iOS. Это связано с недостаточной оптимизацией и высоким потреблением ресурсов на мобильных устройствах. ',
  },
  {
    name: 'Потребление памяти',
    description:
      'Python может потреблять больше памяти для выполнения программ, особенно по сравнению с языками, использующими статическую типизацию. Это может быть проблемой для приложений с ограниченными ресурсами, таких как встраиваемые системы или мобильные устройства.',
  },
]

export const PythonMockDataAddInf = [
  {
    id: '1',
    name: 'Django Rest Framework (DRF)',
    description:
      'Мощный фреймворк для создания веб-API на языке Python с использованием фреймворка Django. Он предоставляет набор инструментов и функций, упрощающих процесс разработки API. Некоторые из ключевых особенностей DRF включают в себя',
    settings: [
      {
        name: 'Сериализация данных',
        description:
          'Предоставляет удобные средства сериализации и десериализации данных между форматами, такими как JSON, XML и другими, что упрощает передачу данных между клиентом и сервером.',
      },
      {
        name: 'Аутентификация и авторизация',
        description:
          'Предоставляет готовые средства для реализации аутентификации и авторизации пользователей, включая различные методы аутентификации (токены, JWT, OAuth) и правила доступа, что обеспечивает безопасность вашего API.',
      },
      {
        name: 'Поддержка CRUD операций',
        description:
          'Автоматизирует обработку запросов CRUD (Create, Read, Update, Delete) для ваших моделей Django, что упрощает создание API для работы с данными вашего приложения',
      },
    ],
  },
  {
    id: '2',
    name: 'Sanic',
    description:
      'Асинхронный веб-фреймворк на языке Python, который позволяет создавать высокопроизводительные веб-приложения с асинхронной обработкой запросов. Он основан на принципах асинхронного программирования и предоставляет множество возможностей для создания масштабируемых и быстрых приложений. Некоторые из ключевых особенностей Sanic',
    settings: [
      {
        name: 'Асинхронная обработка запросов',
        description:
          'Использует асинхронные возможности Python 3.5+ для обработки запросов без блокировки потоков, что позволяет обеспечить высокую производительность и отзывчивость приложения.',
      },
      {
        name: 'Простота использования',
        description:
          'Обладает интуитивно понятным API, аналогичным Flask, что делает его легким для изучения и использования даже для новичков в асинхронном программировании.',
      },
      {
        name: 'Масштабируемость',
        description:
          'Обеспечивает хорошую масштабируемость благодаря использованию асинхронных возможностей Python и оптимизированным механизмам обработки запросов.',
      },
    ],
  },
  {
    id: '3',
    name: 'Аiohttp',
    description:
      'Асинхронная библиотека для работы с HTTP-запросами в языке программирования Python. Она предоставляет возможности как для создания веб-серверов, так и для отправки и обработки HTTP-запросов на стороне клиента. Вот некоторые ключевые особенности aiohttp',
    settings: [
      {
        name: 'Асинхронная обработка HTTP-запросов',
        description:
          'Одной из основных особенностей aiohttp является ее способность обрабатывать HTTP-запросы асинхронно, используя возможности асинхронного программирования в Python. Это позволяет обрабатывать множество запросов параллельно без блокировки потоков, что повышает производительность и масштабируемость приложений.',
      },
      {
        name: 'Гибкость и расширяемость',
        description:
          'aiohttp предоставляет разработчикам гибкие средства для создания как веб-серверов, так и клиентских приложений. Он позволяет легко настраивать и расширять функциональность веб-сервера или клиента в зависимости от конкретных потребностей проекта.',
      },
      {
        name: 'Простота использования',
        description:
          'Несмотря на свою мощь, aiohttp остается относительно простым в использовании. Он предоставляет интуитивно понятный API, который позволяет быстро создавать и настраивать веб-серверы и клиентские приложения с минимальными усилиями.',
      },
    ],
  },
]
