import styles from './Loading.module.scss'
import logo from '../../style/img/LogoAlabuga.svg'
import { useEffect } from 'react'
import { userStore } from 'Stores/UserStore'

export default function Loading() {
  useEffect(() => {
    userStore.setFooter(true)

  }, [])
  return (
    <div className={styles.loading}>
      <img src={logo} alt="logo" />
    </div>
  )
}
