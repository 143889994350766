import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Head } from './components/head/Head'
import { UserTable } from './components/user/UserTable'
import { coursesStore } from 'Stores/CourseStore'
import { TableUsersI } from 'Interfaces/userInterfaces'
import styles from './StatisticTable.module.scss'

export const StatisticTable = observer(() => {
  useEffect(() => {
    coursesStore.getUsersForTable(Number(localStorage.getItem('task')))
    coursesStore.setPrintAnswersCode({})
  }, [])

  const exitFromTable = () => {
    coursesStore.setIsOpenTable(false)
    coursesStore.isOpenAnswer = -1
  }

  return (
    <div className={styles.table__bg} onClick={() => exitFromTable()}>
      <div className={styles.table} onClick={e => e.stopPropagation()}>
        <p className={styles.table__close} onClick={() => exitFromTable()}>
          Закрыть
        </p>
        <Head />
        <div className={styles.table__body}>
          {coursesStore.usersInTable.map((user: TableUsersI, index) => (
            <UserTable key={index} {...user} />
          ))}
        </div>
      </div>
    </div>
  )
})
