import { observer } from 'mobx-react-lite'
import styles from './Work.module.scss'
import { DocumentationI } from 'Interfaces/coursesInterfaces'
import PythonWork from './img/PythonWork.png'
import JavaScriptWork from './img/JavaScriptWork.png'

const Work = observer((data: DocumentationI) => {
  return (
    <section className={styles.work__section}>
      <div className={styles.img__wrapper}>
        <img
          src={
            data.name === 'Backend разработчики' ? PythonWork : JavaScriptWork
          }
          alt=""
        />
      </div>

      <div className={styles.work__information}>
        <h4>{data.name}</h4>
        <p>{data.description}</p>
      </div>
    </section>
  )
})

export default Work
