import { observer } from 'mobx-react-lite'
import styles from './Sides.module.scss'
import { DocumentationI } from 'Interfaces/coursesInterfaces'
import SideCart from '../SideCart/SideCart'
import {
  PythonMockSideDis,
  PythonMockSidePlus,
} from '../../Mock/PuthonMockData'
import {
  JavaScriptMockSideDis,
  JavaScriptMockSidePlus,
} from '../../Mock/JavaScriptMockData'

const Sides = observer((data: DocumentationI) => {
  return (
    <div className={styles.sides}>
      <div className={styles.dignity}>
        <h3>Достоинства</h3>
        <div className={styles.sides__cart__list}>
          {data.name === 'Python'
            ? PythonMockSidePlus.map((data: DocumentationI, index) => {
                return <SideCart {...data} key={index} />
              })
            : JavaScriptMockSidePlus.map((data: DocumentationI, index) => {
                return <SideCart {...data} key={index} />
              })}
        </div>
      </div>
      <hr />
      <div className={styles.flaws}>
        <h3>Недостатки</h3>
        <div className={styles.sides__cart__list}>
          {data.name === 'Python'
            ? PythonMockSideDis.map((data: DocumentationI, index) => {
                return <SideCart {...data} key={index} />
              })
            : JavaScriptMockSideDis.map((data: DocumentationI, index) => {
                return <SideCart {...data} key={index} />
              })}
        </div>
      </div>
    </div>
  )
})

export default Sides
