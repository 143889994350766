import { observer } from 'mobx-react-lite'
import styles from './NewTask.module.scss'
import { adminStore } from 'Stores/AdminStore'
import { NewTaskI } from 'Interfaces/adminInterfaces'

const NewTask = observer((data: NewTaskI) => {
  const { lessonIndex, taskStringIndex, thisTaskIndex, thisTask } = data

  return (
    <li className={styles.admin__new__task}>
      {adminStore.isEditCourse === false &&
        adminStore.isAddOrEditTask === false && (
          <div
            className={styles.admin__new__task__delete}
            onClick={() =>
              adminStore.deleteTask({
                lessonIndex,
                taskStringIndex,
                taskIndex: thisTaskIndex,
              })
            }
          />
        )}
      <p
        className={styles.new__task__name}
        onClick={() =>
          adminStore.setEditOrAddTaskForm({
            lessonIndex,
            taskStringIndex,
            taskIndex: thisTaskIndex,
            editableTask: thisTask,
          })
        }
      >
        {thisTask.name_task}
      </p>
    </li>
  )
})

export default NewTask
