import { useEffect } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { chatStore, userStore } from 'Stores/index'
import { websocketStore } from 'Stores/WebsocketStore'
import Sidebar from 'components/Sidebar/Sidebar'
import {
  Admin,
  CoursePage,
  Home,
  JavaScript,
  Login,
  Profile,
  Python,
  Rating,
  Register,
  TaskPage,
  UserCoursePage,
  Chat,
  TestPage,
} from 'pages/index'
import AnotherProfile from 'pages/Profiles/AnotherProfile/AnotherProfile'
import './style/scss/app.scss'
import StatisticsCourse from 'pages/StatisticsCourse/StatisticsCourse'
import { Footer } from 'components/index'

const App = observer(() => {
  const sidebar =
    window.location.pathname === '/login' ||
    window.location.pathname === '/register' ||
    userStore.loading

  useEffect(() => {
    if (localStorage.getItem('user_token')) {
      websocketStore.connectWS()
      userStore.getUserHeader()
      userStore.setFooter(false)
    }
    if (!localStorage.getItem('user_theme_color')) {
      localStorage.setItem('user_theme_color', 'light')
      userStore.setUserSiteTheme('light')
    }
  }, [localStorage.getItem('user_token')])
  return (
    <>
      <main
        className={
          userStore.userSiteTheme === 'dark' ? 'dark__body' : 'light__body'
        }
        onClick={() => {
          chatStore.setSettings(0)
        }}
      >
        <BrowserRouter>
          {!sidebar && <Sidebar />}
          <Routes>
            <Route path="/" element={<Home />} errorElement={<h1>Ошибка</h1>} />
            <Route path="/login" element={<Login />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/course/:course_id" element={<CoursePage />} />
            <Route
              path="/course/:course_id/user/:user_id"
              element={<UserCoursePage />}
            />

            <Route
              path="/course/:course_id/task/:task_id"
              element={<TaskPage />}
            />
            <Route
              path="/course/:course_id/user/:user_id/task/:task_id"
              element={<TaskPage />}
            />

            <Route
              path="/course/:course_id/testing/:task_id"
              element={<TestPage />}
            />
            <Route
              path="/course/:course_id/user/:user_id/testing/:task_id"
              element={<TestPage />}
            />

            <Route path="/register" element={<Register />} />
            <Route path="/python" element={<Python />} />
            <Route path="/javascript" element={<JavaScript />} />
            <Route
              path="/course/:course_id/statistics"
              element={<StatisticsCourse />}
            />
            <Route path="/administration" element={<Admin />} />
            <Route path="/rating" element={<Rating />} />
            <Route path="/profile/:id" element={<AnotherProfile />} />
            <Route path="/chat" element={<Chat />} />
          </Routes>
          
        </BrowserRouter>
      </main>
    </>
  )
})

export default App
