import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Loading } from 'components/index'
import Lesson from './components/Lesson/Lesson'
import { coursesStore } from 'Stores/index'
import { LessonCuttedI } from 'Interfaces/index'
import LessonSection from './components/LessonSection/LessonSection'
import styles from './CoursePage.module.scss'
import { StatisticTable } from 'components/index'
import PreviewSection from './components/PreviewSection/PreviewSection'

const CoursePage = observer(() => {
  const { course_id } = useParams()

  useEffect(() => {
    if (course_id) {
      coursesStore.getCourse(course_id)
    }
    document.title = 'Страница курса'
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {coursesStore.loading ? <Loading /> : 
        <>
          <PreviewSection/>
          <LessonSection/>
          {coursesStore.isOpenTable && <StatisticTable />}
        </>
      }
    </>
  )
})

export default CoursePage
