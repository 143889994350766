import { observer } from 'mobx-react-lite'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Header from 'components/Sidebar/components/Header/Header'
import { userStore } from 'Stores/UserStore'
import styles from './Sidebar.module.scss'
import logo from 'style/img/LogoAlabuga.svg'
import userLogo from 'style/img/UserIcon.svg'
import { BASE_URL } from '../../consturl'
import arrow_close from 'style/img/arrow-down.svg'
import { bell } from 'components/index'
import { MenuChat } from 'components/Sidebar/components/MenuChat/MenuChat'
import MenuSettings from 'components/Sidebar/components/MenuSettings/MenuSettings'
import { MenuNotification } from 'components/Sidebar/components/MenuNotification/MenuNotification'
import ThemeChanging from 'components/Sidebar/components/ThemeChanging/ThemeChanging'
import menuLogo from './img/Text.png'
import cn from 'classnames'

const Sidebar = observer(() => {
  const [openMenu, setOpenMenu] = useState('')
  const navigate = useNavigate()
  useEffect(() => {
    if (
      !localStorage.getItem('user_token') ||
      localStorage.getItem('user_token') == null
    ) {
      navigate('/login')
    }
    userStore.getUserHeader()
  }, [])

  return (
    <div>
      {userStore.sidebarVisible &&
        <div className={styles.closed__sidebar} onClick={() => userStore.setSidebarVisible(false)}></div>}
      <Header />
      <div
        className={cn(styles.sidebar, {
          [styles.sidebar__open]: userStore.sidebarVisible,
        })}
      >
        <div className={styles.header__sidebar}>
          <div className={styles.logo__block}>
            <img
              src={logo}
              alt='logo'
              onClick={() => {
                navigate('/')
                userStore.setSidebarVisible(false)
              }}
            />
            <div className={styles.user__block}>
              <ThemeChanging />
              <img
                src={cn({
                  [BASE_URL + userStore.user_header.avatar_photo]: userStore.user_header.avatar_photo,
                  [userLogo]: !userStore.user_header.avatar_photo,
                })}
                className={styles.user__logo}
                alt='user-logo'
                onClick={() => {
                  navigate('/profile')
                  userStore.setSidebarVisible(false)
                }}
              />

              <div
                className={styles.arrow__btn}
                onClick={() => userStore.setSidebarVisible(false)}
              >
                <img src={arrow_close} alt='' className={styles.arrow} />
              </div>
            </div>
          </div>

          <div className={styles.menu__navigate}>
            <div
              onClick={() => setOpenMenu('')}
              className={openMenu == '' ? styles.active__btn : ''}
            >
              <img src={menuLogo} alt="" />
            </div>
            <div
              onClick={() => setOpenMenu('notification')}
              className={openMenu == 'notification' ? styles.active__btn : ''}
            >
              <img src={bell} alt="" />
            </div>
            <div
              onClick={() => setOpenMenu('chat')}
              className={openMenu == 'chat' ? styles.active__btn : ''}
            >
              заг
            </div>
          </div>
          <div></div>
        </div>
        {openMenu ? (
          openMenu == 'notification' ? (
            <MenuNotification />
          ) : (
            <MenuChat />
          )
        ) : (
          <MenuSettings />
        )}
      </div>
    </div>
  )
})

export default Sidebar
