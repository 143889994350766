import { observer } from "mobx-react-lite";
import styles from './LogSidebar.module.scss'
import { EntranceInputI } from "Interfaces/userInterfaces";
import { LOG_INPUTS } from "../../LogMock/LogMock";
import { Button } from "components/index";
import { Link, useNavigate } from "react-router-dom";
import { LOG_TXT } from "../../LogMock/LogMock";
import { entranceStore } from "Stores/EntranceStore";
import { FormsLogI, FormsRegI } from "Interfaces/helpInterfaces";
import EntranceInput from "pages/Entrance/components/EntranceInput/EntranceInput";


const LogSidebar = observer(() => {
    const navigate = useNavigate()
    const { logTitle, logBtn, regTxt, regLink } = LOG_TXT
    const { firstName, lastName, email, password, password2 } = entranceStore
    const form: FormsLogI = {
        email: email,
        password: password,
    }

    return (
        <div className={styles.sidebar}>
            <div className={styles.sidebar__form}>
                <h2>{logTitle}</h2>
                {LOG_INPUTS.map((input: EntranceInputI, index) => {
                    return (<EntranceInput {...input} key={index} />)
                })}
                <Button text={logBtn} className={styles.log__btn} onClick={() => entranceStore.login(form, navigate)} />
                <div className={styles.register}>
                    <p className={styles.register__text}>{regTxt}</p>
                    <Link to='/register'><p className={styles.register__link}>{regLink}</p></Link>
                </div>
            </div>
        </div>
    )
})
export default LogSidebar