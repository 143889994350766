import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Loading, Button } from 'components/index'
import {
  emailIcon,
  openPasswordIcon,
  errorOpenPasswordIcon,
  errorClosePasswordIcon,
  closePasswordIcon,
  errorEmailIcon,
  lockIcon,
  errorLockIcon,
} from './img/index'
import { userStore } from 'Stores/index'
import logo from 'style/img/LogoAlabuga.svg'
import styles from './Login.module.scss'
import 'style/scss/app.scss'
import PreviewSection from '../components/PreviewSection/PreviewSection'
import LogSidebar from './components/RegSidebar/LogSidebar'

const Login = observer(() => {
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isOpenPassword, setIsOpenPassword] = useState(false)

  const form = {
    email,
    password,
  }
  useEffect(() => {
    document.title = 'Авторизация'
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {userStore.loading ? <Loading /> : (
        <section className={styles.login} >
          <PreviewSection/>
          <LogSidebar />
        </section>
      )}
    </>
  )
})

export default Login
