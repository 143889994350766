import { observer } from "mobx-react-lite"
import { Course } from "components/index"
import { coursesStore } from "Stores/CourseStore"
import styles from './CoursesSection.module.scss'
import { CourseCuttedI } from "Interfaces/coursesInterfaces"

const CoursesSection = observer(() => {
    return(
      <div className={styles.content__wrapper}>
        <div className="container">
          <section className={styles.courses__block}>
            <h2 className={styles.courses__list__title}>Курсы для начинающих</h2>
            <hr />
            <ul className={styles.courses__list}>
              {coursesStore.coursesList.map((data: CourseCuttedI) => {
                return <Course key={data.id} {...data} />
              })}
            </ul>
          </section>
        </div>
      </div>
        
    )
})

export default CoursesSection