import { useRef, useEffect, useState, FC } from 'react'
import questionOpenStatusIconDark from '../../img/question-open-status-icon-dark.svg'
import questionOpenStatusIconLight from '../../img/question-open-status-icon-light.svg'
import { userStore } from 'Stores/UserStore'
import { IQuestion } from 'Interfaces/index'
import styles from './Questions.module.scss'


const Questions: FC<IQuestion> = ({ question, answer }) => {
  const [isOpenQuestion, setIsOpenQuestion] = useState(false)
  const [questionHeight, setQuestionHeight] = useState(0)
  const questionState = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const bottomBlock: HTMLDivElement | null = questionState.current
    if (bottomBlock) {
      setQuestionHeight(bottomBlock.scrollHeight)
    }
  }, [])


  return (
    <li className={styles.question}>
      <div
        className={styles.title__block}
        onClick={() => setIsOpenQuestion(!isOpenQuestion)}
      >
        <h2 className={styles.question__title}>{question}</h2>
        <img
          src={
            userStore.userSiteTheme === 'dark'
              ? questionOpenStatusIconDark
              : questionOpenStatusIconLight
          }
          alt='question-icon'
          style={isOpenQuestion ? { rotate: `-45deg` } : {}}
        />
      </div>
      <div
        ref={questionState}
        style={
          isOpenQuestion
            ? { maxHeight: `${questionHeight + 50}px`, paddingBottom: `50px` }
            : { maxHeight: `${0}px`, padding: 0 }
        }
        className={styles.question__answer__block}
      >
        <p className={styles.question__text}>{answer}</p>
      </div>
      {/*<hr />*/}
    </li>
  )
}


export default Questions