import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { Button, Loading, Footer, StatisticTable } from 'components/index'
import { UserData, Lesson } from 'pages/index'
import { userStore, coursesStore } from 'Stores/index'
import { LessonCuttedI } from 'Interfaces/index'
import programLogo from '../CoursePage/img/course-info-image.png'
import styles from './UserCoursePage.module.scss'

const UserCoursePage = observer(() => {
  const { course_id } = useParams()
  const { user_id } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    if (userStore.token == '') {
      navigate('/')
    } else {
      if (user_id && course_id) {
        coursesStore.getUserCourses(Number(user_id), Number(course_id))
      }
    }
    document.title = 'Страница курса пользователя'
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {/* Анимация загрузки */}
      {coursesStore.loading ? (
        <Loading />
      ) : coursesStore.error ? (
        <p>{coursesStore.error}</p>
      ) : (
        <>
          <section className={styles.course__description__wrapper}>
            <div className={styles.course__description__content__wrapper}>
              <div className={styles.course__description}>
                <UserData />
                <h2 className={styles.course__description__title}>
                  {coursesStore.course.name_course}
                </h2>
                <div
                  className={styles.course__description__text}
                  dangerouslySetInnerHTML={{
                    __html: coursesStore.course.detailed_description,
                  }}
                />
                <div className={styles.btn__next__description}>
                  {coursesStore.course.name_course === 'Python' ? (
                    <Button
                      text="Подробнее о курсе"
                      onClick={() => navigate(`/python`)}
                    />
                  ) : (
                    coursesStore.course.name_course === 'JavaScript' && (
                      <Button
                        text="Подробнее о курсе"
                        onClick={() => navigate(`/javascript`)}
                      />
                    )
                  )}
                  <Button
                    text="Продолжить обучение"
                    onClick={() =>
                      navigate(`task/${coursesStore.last_completed_task}`)
                    }
                  />
                </div>
              </div>
              <img
                alt="programmer"
                src={programLogo}
                className={styles.course__preview__image}
              />
            </div>
          </section>
          <section className={styles.course__lessons__wrapper}>
            <div className="container">
              {coursesStore.course.lesson.map((lesson: LessonCuttedI) => {
                return <Lesson key={lesson.id} {...lesson} />
              })}
            </div>
          </section>
          {coursesStore.isOpenTable && <StatisticTable />}
        </>
      )}
    </>
  )
})

export default UserCoursePage
