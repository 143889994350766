import { ButtonCloseFormI } from 'Interfaces/helpInterfaces'
import styles from './ButtonCloseForm.module.scss'

export default function ButtonCloseForm(data: ButtonCloseFormI) {
  return (
    <div className={styles.close__form__button__wrapper}>
      <button onClick={data.onClick} className={styles.close__form__button} />
    </div>
  )
}
