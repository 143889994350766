import { FC } from 'react'
import { ButtonI } from 'Interfaces/helpInterfaces'
import styles from './ButtonCourse.module.scss'
import { coursesStore } from 'Stores/CourseStore'

export const ButtonCourse: FC<ButtonI> = ({ onClick, text, className }) => {
  return (
    <button 
      onMouseEnter={() => coursesStore.setBtnCourseHoverEvent(false)} 
      onMouseLeave={() => coursesStore.setBtnCourseHoverEvent(false)}
      className={[styles.button__course, className].join(' ')} 
      onClick={onClick}>
      {text}
    </button>
  )
}
