import { observer } from 'mobx-react-lite'
import styles from './Chat.module.scss'
import { websocketStore } from 'Stores/WebsocketStore'
import LeftChatsPanel from './components/LeftChatsPanel/LeftChatsPanel'
import { chatStore } from 'Stores/ChatStore'
import { userStore } from 'Stores/UserStore'
import { useEffect } from 'react'
import MessagePanel from 'pages/Chat/components/MessagePanel/MessagePanel'
import { WEBSOCKET_MSG } from '../../Const'
import SelectChat from 'pages/Chat/components/SelectChat/SelectChat'

const Chat = observer(() => {
  useEffect(() => {
    if (userStore.online) websocketStore.sendMessage(WEBSOCKET_MSG.USER_CHATS)
    userStore.setFooter(true)
  }, [userStore.online])

  return (
    <section className={styles.section__chat}>
      <div className={styles.content_section_chat}>
        <LeftChatsPanel />
        <div
          className={styles.chat__panel}
          onContextMenu={event => {
            event.preventDefault()
          }}
        >
          {chatStore.chat.interlocutor ? <MessagePanel /> : <SelectChat />}
        </div>
      </div>
    </section>
  )
})

export default Chat
