import { useRef } from 'react'
import { observer } from 'mobx-react-lite'
import {
  Button,
  Input,
  ButtonCloseForm,
  ButtonSetImage,
} from 'components/index'
import { adminStore } from 'Stores/AdminStore'
import styles from './EditOrAddAchievementForm.module.scss'

const EditOrAddAchievementForm = observer(() => {
  const editOrAddAchievementForm = useRef<HTMLFormElement>(null)

  return (
    <>
      {(adminStore.isAddAchievement || adminStore.isEditAchievement) && (
        <section className={styles.add__achievement__block}>
          <h2 className={styles.add__achievement__block__title}>
            Добавить достижение
          </h2>
          <form
            ref={editOrAddAchievementForm}
            className={styles.add__achievement__form}
          >
            <ButtonCloseForm onClick={adminStore.closeAchievementForm} />
            <Input
              title="Название достижения"
              titleClass="add__form__title"
              inputType="text"
              inputClass="add__form__input"
              inputName="name"
              inputDefaultValue={adminStore.achievement_name}
            />
            <ButtonSetImage
              title="Фото достижения"
              titleClass="add__form__title"
              buttonName="Добавить"
              inputName="achievement_photo"
            />
            <Input
              title="Как получить достижение"
              titleClass="add__form__title"
              inputType="text"
              inputClass="add__form__input"
              inputName="description"
              inputDefaultValue={adminStore.achievement_description}
            />
            <Button
              text="Опубликовать"
              onClick={event =>
                adminStore.publicNewAchievement({
                  event,
                  editOrAddAchievementForm,
                })
              }
            />
          </form>
        </section>
      )}
    </>
  )
})

export default EditOrAddAchievementForm
