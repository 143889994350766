import { FC } from 'react'
import { TheoryChoiceI } from 'Interfaces/index'
import style from './choice.module.scss'

export const CheckboxChoice: FC<TheoryChoiceI> = props => {
  return (
    <label className={style.choice__text}>
      <input
        className={style.choice__input_checkbox}
        type="checkbox"
        name={`choice-${props.name}`}
        value={Number(props.value)}
        onChange={props.onChange}
      />
      {props.choice}
    </label>
  )
}
