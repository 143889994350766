import { observer } from 'mobx-react-lite'
import styles from './MenuNotification.module.scss'
import { userStore } from 'Stores/UserStore'
import { NotificationsI } from 'Interfaces/userInterfaces'
import Notification from '../Notification/Notification'

export const MenuNotification = observer(() => {
  return (
    <div className={styles.menu__notification}>
      <p className={styles.title__notification__text}>Уведомления</p>
      <hr />
      <div className={styles.notification__list}>
        {userStore.user_header.notifications?.length ? (
          userStore.user_header.notifications.map(
            (data: NotificationsI, index) => {
              return (
                <>
                  <Notification {...data} key={index} />
                  <hr />
                </>
              )
            },
          )
        ) : (
          <h2 className={styles.not__notification}>
            Здесь пока нет уведомлений
          </h2>
        )}
      </div>
    </div>
  )
})
