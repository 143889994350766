import { observer } from 'mobx-react-lite'
import styles from './MenuSettings.module.scss'
import ButtonNavigate from 'components/Sidebar/components/ButtonNavigate/ButtonNavigate'
import hrIcon from 'style/img/hr-icon.svg'
import { useNavigate } from 'react-router-dom'
import { userStore } from 'Stores/UserStore'
import adminIcon from '../../img/Call center.png'
import exitIcon from 'style/img/exit-icon.svg'
import rating from '../../img/Rating.png'
import React from 'react'

const MenuSettings = observer(() => {
  const navigate = useNavigate()
  return (
    <div className={styles.menu__settings}>
      <p>Меню</p>
      <hr />
      <div>
        <ButtonNavigate
          onClick={() => navigate('//hr.alabuga.ru/')}
          image={hrIcon}
          text="Платформа"
        />

        <hr />
        {userStore.user_header.is_staff && (
          <>
            <ButtonNavigate
              onClick={() => navigate('/administration')}
              image={adminIcon}
              text="Админ-панель"
            />
            <hr />
          </>
        )}

        {userStore.user_header.is_student && (
          <>
            <ButtonNavigate
              onClick={() => {
                userStore.setSidebarVisible(false)
                navigate('/rating')
              }}
              image={rating}
              text="Рейтинг"
            />
            <hr />
          </>
        )}
        <ButtonNavigate
          onClick={() => {
            userStore.logOut(navigate)
            window.location.reload()
          }}
          image={exitIcon}
          text="Выйти"
        />
      </div>
    </div>
  )
})
export default MenuSettings
