import { observer } from "mobx-react-lite"
import styles from './QuestionSection.module.scss'
import Question from '../Question/Question'

const QuestionSection = observer(() => {
    return (
      <div className={styles.content__wrapper}>
        <div className="container">
        <section className={styles.home__questions__block}>
          <div className={styles.home__questions__content__wrapper}>
            <h2 className={styles.home__questions__title}>Ответы на вопросы</h2>
            <hr />
            <ul className={styles.home__questions__list}>
              <Question
                question='Сколько получают IT-Специалисты?'
                answer='По данным источника, средняя зарплата в IT-сфере — 179 000 рублей в месяц. Медианная зарплата IT-специалистов растёт в среднем на 7500 рублей каждые полгода.'
              />
              <Question
                question='Трудно ли учиться на IT-специалиста?'
                answer='Учеба на IT-специалиста может быть вызовом, но также очень увлекательной и перспективной.В целом, учиться на IT-специалиста может быть вызовом, но при правильной мотивации и упорстве это достижимо.'
              />
              <Question
                question='Получу ли я диплом, который подтверждает, что я прошел обучение?'
                answer='При успешном завершении обучения по IT, вы получаете диплом, сертификат или другое официальное подтверждение'
              />
            </ul>
          </div>
        </section>
      </div>
      </div>
      
      
    )
})

export default QuestionSection