import { FormEvent, useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router-dom'
import { TaskCuttedI } from 'Interfaces/coursesInterfaces'
import { coursesStore, userStore } from 'Stores/index'
import styles from './Task.module.scss'

const Task = observer((data: TaskCuttedI) => {
  const navigate = useNavigate()

  useEffect(() => {
    if (data.flag == 'in progress') {
      coursesStore.setLastCompletedTask(Number(data.id))
    }
  }, [])

  const printTable = (id: number) => {
    localStorage.setItem('task', String(id))
    coursesStore.setIsOpenTable(!coursesStore.isOpenTable)
  }

  const handleRedirect = (event: FormEvent<HTMLDivElement>) => {
    event.stopPropagation()
    {
      if (
        (data.flag === 'in progress' && data.task_type === 'Theory') ||
        (data.flag === 'true' && data.task_type === 'Theory')
      ) {
        navigate(`testing/${data.id}`)
      } else if (
        (data.flag === 'in progress' && data.task_type !== 'Theory') ||
        (data.flag === 'true' && data.task_type !== 'Theory')
      ) {
        navigate(`task/${data.id}`)
      } else if (
        userStore.user_header.is_staff &&
        window.location.href.includes(`user`) &&
        data.task_type !== 'Theory'
      ) {
        navigate(`task/${data.id}`)
      } else if (
        userStore.user_header.is_staff &&
        window.location.href.includes(`user`) &&
        data.task_type === 'Theory'
      ) {
        navigate(`testing/${data.id}`)
      } else {
        navigate(``)
      }
    }
  }

  return (
    <div className={styles.task} onClick={event => handleRedirect(event)}>
      <h2 className={styles.task__title}>{data.name_task}</h2>
      <p
        className={`${styles.task__complexity} ${data.complexity === 'Easy' ? styles.easy : data.complexity === 'Medium' ? styles.medium : styles.hard}`}
      >
        {data.complexity}
      </p>
      <div onClick={e => e.stopPropagation()}>
        {userStore.user_header.is_staff && (
          <p
            className={styles.task__result}
            onClick={() => printTable(data.id)}
          >
            Результаты
          </p>
        )}
      </div>

      <div className={styles.task__container}>
        <p className={styles.task__exp}>
          Награда:{' '}
          <span className={styles.task__exp__quantity}>{data.xp} exp</span>
        </p>
        <div
          className={`${styles.task__isdone} ${data.flag === 'true' ? styles.done__color : data.flag === 'in progress' ? styles.notdone__color : styles.close__color}`}
        >
          {data.flag === 'in progress' ? (
            <p className={styles.task__text__notdone}>В процессе</p>
          ) : data.flag === 'true' ? (
            <p className={styles.task__text__done}>Выполнено</p>
          ) : (
            <p className={styles.task__text__close}>Закрыто</p>
          )}

          <div
            className={
              data.flag === 'true'
                ? `${styles.task__isdone__status} ${styles.done}`
                : data.flag === 'in progress'
                  ? `${styles.task__isdone__status} ${styles.notdone}`
                  : `${styles.task__isdone__status} ${styles.close}`
            }
          />
        </div>
      </div>
    </div>
  )
})

export default Task
