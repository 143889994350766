import { observer } from 'mobx-react-lite'
import styles from './FooterBlock.module.scss'
import phone from 'components/Footer/img/Phone.png'
import mail from 'components/Footer/img/Почта.png'
import tg from 'components/Footer/img/Телеграмм.png'

interface FooterBlockI {
  title: string
  description: string
  href1: string
  href2: string
  href3: string
  text1: string
  text2: string
  text3: string
}

const FooterBlock = observer((data: FooterBlockI) => {
  return (
    <div className={styles.footer__block}>
      <h2 className={styles.footer__block__title}>{data.title}</h2>
      <hr />
      <p className={styles.footer__block__text}>{data.description}</p>
      <div className={styles.footer__block__links__wrapper}>
        <a href={data.href1} className={styles.footer__link}>
          <img src={phone} alt="phone-icon" />
          {data.text1}
        </a>
        <a href={data.href2} className={styles.footer__link}>
          <img src={mail} alt="mail-icon" />
          {data.text2}
        </a>
        <a href={data.href3} className={styles.footer__link}>
          <img src={tg} alt="telegram-icon" />
          {data.text3}
        </a>
      </div>
    </div>
  )
})

export default FooterBlock
