import { useNavigate } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { TableUsersI } from 'Interfaces/userInterfaces'
import { coursesStore } from 'Stores/CourseStore'
import userLogo from 'style/img/UserIcon.svg'
import { AnswerModal } from 'components/index'
import { BASE_URL } from 'consturl'
import styles from './UserTable.module.scss'

export const UserTable = observer((data: TableUsersI) => {
  const navigate = useNavigate()

  return (
    <div className={styles.table__user}>
      <div
        className={styles.table__user_avatar_block}
        onClick={() => navigate(`/profile/${data.user.id}`)}
      >
        <img
          src={
            data.user.avatar_photo
              ? BASE_URL + data.user.avatar_photo
              : userLogo
          }
          className={styles.table__user_avatar}
          alt={'avatar'}
        />
      </div>
      <p
        className={styles.table__user_text}
        onClick={() => navigate(`/profile/${data.user.id}`)}
      >
        {data.user.last_name}
      </p>
      <p
        className={styles.table__user_text}
        onClick={() => navigate(`/profile/${data.user.id}`)}
      >
        {data.user.first_name}
      </p>
      <p
        className={styles.table__user_text}
        onClick={() => navigate(`/profile/${data.user.id}`)}
      >
        {data.user.email}
      </p>
      <p
        className={styles.table__link}
        onClick={() => coursesStore.setIsOpenAnswer(data.id)}
      >
        подробнее
      </p>
      {coursesStore.isOpenAnswer == data.id && (
        <AnswerModal answers={data.answers} code_area={data.code_area} />
      )}
    </div>
  )
})
