import { observer } from 'mobx-react-lite'
import styles from './Header.module.scss'
import { userStore } from 'Stores/UserStore'
import { Link } from 'react-router-dom'

const Header = observer(() => {
  return (
    <header
      className={
        userStore.sidebarVisible
          ? `${styles.header} ${styles.header__open}`
          : `${styles.header} `
      }
    >
      <div className={
        userStore.sidebarVisible
          ? `${styles.content__wrapper} ${styles.content__wrapper__open}`
          : `${styles.content__wrapper} `
      }>
        <div
          className={
            userStore.sidebarVisible
              ? `${styles.btn__sidebar__open}`
              : `${styles.btn__sidebar} `
          }
          onClick={() => {
            userStore.setSidebarVisible(true)
          }}
        >
          <hr />
          <hr />
          <hr />
        </div>
        <Link to='/'><h2 className={styles.title}>Alabuga Course</h2></Link>
      </div>
    </header>
  )
})

export default Header
