import { makeAutoObservable } from 'mobx' 
import axios from 'axios'
import {
  RatingI,
  UserFullI,
  UserI,
  FormsLogI,
  FormsRegI,
  AchievementI,
  StartedCoursesI,
  EntranceInputI
} from 'Interfaces/index'
import { BASE_URL } from 'consturl'
import { AnotherUserI, NotificationsI } from 'Interfaces/userInterfaces'
import { NavigateFunction } from 'react-router-dom'

class UserStore {
  constructor() {
    makeAutoObservable(this)
  }
  token = localStorage.getItem('user_token')
  loading = false
  user: UserFullI = {}
  achievement: Array<AchievementI> = []
  error = false
  started_courses: Array<StartedCoursesI> = []
  user_header: UserI = {}
  rating: RatingI = {
    top_users: [],
    current_user: {
      id: 0,
      place: 0,
      avatar_photo: null,
      first_name: '',
      last_name: '',
      rank: '',
      exp: 0,
    },
  }
  notifications: NotificationsI[] = []
  userSiteTheme = localStorage.getItem('user_theme_color')
  online?: boolean = false
  AnotherProfile?: AnotherUserI
  started_courses_another_user: Array<StartedCoursesI> = []
  sidebarVisible = false
  footer =
    window.location.pathname.includes('login') ||
    window.location.pathname.includes('register') ||
    window.location.pathname.includes('chat')  ||
    window.location.pathname.includes('profile')
  
  setFooter(value: boolean) {
    this.footer = value
  }

  setUserSiteTheme(value: string) {
    if (!window.location.href.includes('task')) {
      this.userSiteTheme = value
    } else {
      this.userSiteTheme = value
    }
    localStorage.setItem('user_theme_color', value)
  }

  setLoading(value: boolean) {
    this.loading = value
  }

  setError(value: boolean) {
    this.error = value
  }

  setSidebarVisible(value: boolean) {
    this.sidebarVisible = value
  }

  setUser(user: UserFullI) {
    this.user = user
  }

  setUserAchievement(achievement: AchievementI[]) {
    this.achievement = achievement
  }

  setRating(value: RatingI) {
    this.rating = value
  }

  setUserHeader(value: UserI) {
    this.user_header = value
  }

  setStartedCourses(value: StartedCoursesI[]) {
    this.started_courses = value
  }

  setNotification(value: NotificationsI[]) {
    this.notifications = value
  }

  setOnline(value: boolean) {
    this.online = value
  }

  setAnotherProfile(value: AnotherUserI) {
    this.AnotherProfile = value
  }

  setStarted_courses_another_user(value: StartedCoursesI[]) {
    this.started_courses_another_user = value
  }

  getUserHeader() {
    axios
      .get(`${BASE_URL}/api/v1/header`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(response => {
        this.setUserHeader(response.data)
      })
  }

  logOut = (navigate: NavigateFunction) => {
    axios
      .post(`${BASE_URL}/api/v1/logout`, {
        headers: {
          Authorization: `Bearer ${this.token}`,
        },
      })
      .then(response => {
        console.log(response)
      })
    localStorage.setItem('user_token', '')
    navigate('/login')
  }

  get_user() {
    this.setLoading(true)
    this.setError(false)
    axios
      .get(`${BASE_URL}/api/v1/get_user`, {
        headers: { Authorization: `Bearer ${this.token}` },
      })
      .then(response => {
        this.setUserAchievement(response.data.achievement)
        this.setUser(response.data)
        this.setLoading(false)
        this.setStartedCourses(response.data.courses)
      })
      .catch(() => {
        this.setError(true)
      })
  }

  getTopUsers() {
    axios.get(BASE_URL + '/api/v1/top_users', {
      headers: {
        Authorization: 'Bearer ' + this.token
      }
    })
      .then(data => {
        this.setRating(data.data)
      })
  }

  searchTopUsers(text: string) {
    axios.post(BASE_URL + '/api/v1/search_top_users', {
      body: text
    }, {
      headers: {
        Authorization: localStorage.getItem('user_token'),
        'Content-Type': 'application/json;charset=utf-8',
      },
    })
      .then(data => {
        this.setRating(data.data)
      })
  }

}

export const userStore = new UserStore()
