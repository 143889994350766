import { entranceStore } from "Stores/EntranceStore"
const {email, password} = entranceStore
import { emailIcon, openPasswordIcon, closePasswordIcon, lockIcon } from '../img/index'
export const LOG_INPUTS = [
    {
        field: email,
        logo: emailIcon,
        label: 'Электронный адрес',
        placeholder: 'example@mail.ru',
        onChange: (value: string) => entranceStore.setEmail(value)
    },
    {
        field: password,
        logo: lockIcon,
        label: 'Пароль',
        placeholder: 'Введите пароль',
        onChange: (value: string) => entranceStore.setPassword(value)
    },
]

export const LOG_TXT = {
    title: 'Alabuga Courses',
    preTitle: 'Здесь рождаются специалисты мирового уровня',
    logTitle: 'Авторизация',
    logBtn: 'Авторизоваться',
    regTxt: 'Нет аккаунта?',
    regLink: 'Зарегистрируйтесь!'
}