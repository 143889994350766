import { observer } from 'mobx-react-lite'
import styles from './ThemeChanging.module.scss'
import { userStore } from 'Stores/UserStore'
import lightMoon from 'style/img/moon-light.svg'
import darkSun from 'style/img/sun-dark.svg'
import React from 'react'

const ThemeChanging = observer(() => {
  return (
    <div className={styles.theme__changing}>
      <button
        onClick={() => {
          if (userStore.userSiteTheme === 'light') {
            userStore.setUserSiteTheme('dark')
          } else {
            userStore.setUserSiteTheme('light')
          }
        }}
        className={styles.theme__changing__dark__button}
      >
        <img
          src={userStore.userSiteTheme === 'dark' ? lightMoon : darkSun}
          alt="moon-icon"
        />
      </button>
    </div>
  )
})
export default ThemeChanging
