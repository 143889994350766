import { observer } from 'mobx-react-lite'
import styles from './UserCartStatCourse.module.scss'
import { BASE_URL } from '../../../../consturl'
import userLogo from 'style/img/UserIcon.svg'
import { StatisticsUserCourseI } from 'Interfaces/coursesInterfaces'

const UserCartStatCourse = observer((data: StatisticsUserCourseI) => {
  return (
    <div className={styles.stat__user__course}>
      <hr />
      <div className={styles.stat__user__inf}>
        <div className={styles.user__inf}>
          <img
            src={data.avatar_photo ? BASE_URL + data.avatar_photo : userLogo}
            alt=""
          />
          <p>
            {data.first_name} {data.last_name}
          </p>
        </div>
        <p className={styles.task__count}>
          {data.completed_task_count}/{data.task_count}
        </p>
        <p className={styles.completed__percentage}>
          {data.completion_percentage}
        </p>
        <p className={styles.email}>{data.email}</p>
      </div>
    </div>
  )
})

export default UserCartStatCourse
