import { observer } from 'mobx-react-lite'
import userLogo from 'style/img/UserIcon.svg'
import { userStore, adminStore } from 'Stores/index'
import { BASE_URL } from 'consturl'
import { UserBlockI } from 'Interfaces/adminInterfaces'
import { useNavigate } from 'react-router-dom'
import styles from './UserBlock.module.scss'

const UserBlock = observer((data: UserBlockI) => {
  const { id, index, avatar_photo, first_name, last_name, rank, is_banned } =
    data
  const navigate = useNavigate()
  return (
    <li
      className={`${styles.user__card} ${userStore.userSiteTheme === 'light' && styles.light}`}
      onClick={() => {
        navigate('/profile/' + data.id)
      }}
    >
      <div className={styles.user__title__section__ban}>
        <img
          className={styles.user__card__avatar}
          src={avatar_photo ? `${BASE_URL}${avatar_photo}` : userLogo}
          alt="user-icon"
        />
        <div className={styles.user__info__banned}>
          <p
            className={styles.user__card__username}
          >{`${first_name} ${last_name}`}</p>
          <p className={styles.user__card__rank}>{rank}</p>
        </div>
      </div>

      <div className={styles.ban__btn} onClick={e => e.stopPropagation()}>
        {is_banned ? (
          <button
            className={styles.user__card__ban__button}
            onClick={() => adminStore.unbanUser({ id, index, user: data })}
          >
            Разбан
          </button>
        ) : (
          <button
            className={styles.user__card__ban__button}
            onClick={() => adminStore.banUser({ id, index, user: data })}
          >
            Бан
          </button>
        )}
      </div>
    </li>
  )
})

export default UserBlock
