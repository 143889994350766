import { FC } from 'react'
import { TextareaI } from 'Interfaces/index'
import styles from './Textarea.module.scss'

export const Textarea: FC<TextareaI> = (props) => {
  return (
    <label className={props.labelClass && styles[props.labelClass]}>
      {props.title && (
        <p className={props.titleClass && styles[props.titleClass]}>
          {props.title}
        </p>
      )}
      <textarea
        className={props.textareaClass && styles[props.textareaClass]}
        name={props.textareaName}
        defaultValue={props.defaultTextareaValue}
      ></textarea>
    </label>
  )
}
