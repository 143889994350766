import { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import { Loading } from 'components/index'
import RatingUser from './components/RatingUser/RatingUser'
import { adminStore, userStore, websocketStore } from 'Stores/index'
import userLogo from 'style/img/UserIcon.svg'
import styles from './Rating.module.scss'
import { WEBSOCKET_MSG } from '../../Const'

const Rating = observer(() => {
  const [message, setMessage] = useState('')
  useEffect(() => {
    document.title = 'Рейтинг'
    window.scrollTo(0, 0)
    userStore.setFooter(true)
    userStore.getTopUsers()
    if (userStore.online) {
      websocketStore.sendMessage(WEBSOCKET_MSG.TOP_USERS)
    }
  }, [userStore.online])

  return (
    <>
      {userStore.loading ? (
        <Loading />
      ) : (
        <>
          <section className={styles.rating__page}>
            <div className={styles.rating__page__content__wrapper}>
              <div className={styles.rating__header}>
                <p className={styles.rating__header__place}>Место</p>
                <p className={styles.rating__header__user}>Пользователь</p>
                <div className={styles.panel__search}>
                  <button
                    className={styles.panel__search__button}
                    onClick={() => {
                      userStore.searchTopUsers(message)
                      setMessage('')
                    }}
                  />
                  <input
                    type="text"
                    onChange={event => setMessage(event.target.value)}
                    placeholder="Поиск"
                    value={message}
                    className={styles.rating__search__input}
                    onFocus={() => adminStore.setIsSearch(false)}
                  />
                </div>
              </div>
              <hr />
              <ul className={styles.rating__list}>
                {userStore.rating.top_users.map((rating_user, index) => {
                  return <RatingUser key={index} userdata={rating_user} />
                })}
              </ul>
            </div>
            <div className={styles.this__user__rating}>
              <div className={styles.this__user__rating__content__wrapper}>
                {userStore.rating.current_user.place >= 4 ? (
                  <p className={styles.this__user__place}>
                    {userStore.rating.current_user.place}
                  </p>
                ) : (
                  <div className={styles.this__user__place__wrapper}>
                    <div
                      className={`${styles.this__user__top__place} ${
                        userStore.rating.current_user.place === 3
                          ? styles.top__three__place
                          : userStore.rating.current_user.place === 2
                            ? styles.top__two__place
                            : styles.top__one__place
                      }`}
                    />
                  </div>
                )}
                <div className={styles.this__user}>
                  <div className={styles.this__user__image__wrapper}>
                    <img
                      className={styles.this__user__image}
                      src={userLogo}
                      alt="user-icon"
                    />
                  </div>
                  <div className={styles.this__user__info}>
                    <p className={styles.this__user__info__name}>
                      {`${userStore.rating.current_user.first_name} ${userStore.rating.current_user.last_name}`}
                    </p>
                    <p className={styles.this__user__info__status}>
                      {userStore.rating.current_user.rank}
                    </p>
                  </div>
                </div>
                <p className={styles.this__user__exp}>
                  {userStore.rating.current_user.exp}xp
                </p>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  )
})

export default Rating
