import { observer } from 'mobx-react-lite'
import { UserChatI } from 'Interfaces/chatinterface'
import { BASE_URL } from '../../../../consturl'
import userLogo from 'style/img/UserIcon.svg'
import styles from './UserCartSidebar.module.scss'
import React from 'react'
import { websocketStore } from 'Stores/WebsocketStore'
import { WEBSOCKET_MSG } from '../../../../Const'

export const UserCartSidebar = observer((data: UserChatI) => {
  const fio =
    data.interlocutor.first_name +
    ' ' +
    (data.interlocutor.last_name && data.interlocutor.last_name[0])
  const nickName =
    fio.split('').join('').length >= 6 ? fio.substr(0, 6) + '...' : fio
  return (
    <div className={styles.user__cart__sidebar}>
      <img
        src={
          data.interlocutor.avatar_photo
            ? `${BASE_URL}${data.interlocutor.avatar_photo}`
            : userLogo
        }
        className={styles.user__logo}
        alt="user-logo"
        onClick={() =>
          websocketStore.sendMessage(WEBSOCKET_MSG.CHAT, data.interlocutor?.id)
        }
      />
      <p>{nickName}</p>
    </div>
  )
})
